@charset "UTF-8";
// ******************************
// [004] グローバルナビゲーション
// ******************************
.glbNavi {
  display: flex;
  width: 800px;
//  width: 900px;
  height: 40px;
  .topPage & {
    @include positionTR(60px, 50px, 300);
    font-size: 16px;
  }
  &__pageLink {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    margin-right: 30px;
    & li {
      & a {
        display: block;
        color: $c-white;
        transition: .3s;
        &:hover {
          border-bottom: 3px solid $c-white;
          transition: .3s;
        }
      }
    }
  }
  &__contactBtn {
    display: block;
    font-size: 0;
    transition: .3s;
    & + .glbNavi__contactBtn {
//      margin-left: 20px;
      margin: 0 20px;
    }
    & img {
      display: block;
      transition: .3s;
      .topPage & {
        @include hoverShadow($c-black);
      }
    }
  }
  @include mq() {
    display: none;
  }
}






















