@charset "UTF-8";
// ******************************
// [003] グローバルヘッダー
// ******************************
@keyframes naviLogoAnime {
  0% { transform: translateY(0px); }
  25% { transform: translateY(15px); }
  50% { transform: translateY(20px); }
  75% { transform: translateY(15px); }
  100% { transform: translateY(0px); }
}

.topHeader {
  position: relative;
  font-size: 0;
  color :$c-white;
  @include dotOverlay();
  &__logo {
    display: block;
    @include positionTL(20px, 30px, 300);
//    height: 120px;
    height: 80px;
    filter: drop-shadow(0 0 1.5px #333);
    & img {
      display: block;
      height: 100%;
    }
    @include mq() {
      position: fixed;
      height: 50px;
    }
  }
  &__video {
    width: 100%;
    height: 780px;
    object-fit: cover;
    @include mq() {
      height: 100vw;
    }
  }
  &__catchphrase {
    @include positionTL(calc(50% - 120px), calc(50% - 400px), 300);
//    width: 800px;
//    width: 250px;
    height: 240px;
//    background-color: #ff9;
    @include mq() {
      @include positionTL(calc(50% - 80px), calc(50% - 180px), 300);
      height: 160px;
    }
    & picture {
      display: block;
//      width: 250px;
      height: 100%;
      & img {
        display: block;
        height: 100%;
//        width: 100%;
      }
      & p {
        display: none;
      }
    }
  }
  &__scrollBtn {
    @include positionBL(calc(50px + 34.5px), calc(50% - 97.5px), 300);
    height: 69px;
    font-size: 0;
    animation: naviLogoAnime 3s infinite linear;
    & img {
      display: block;
      height: 100%;
      transition: .3s;
    }
    @include mq() {
      @include positionBL( 30px, calc(50% - 56.51px), 300);
      height: 40px;
    }
  }
} /*トップページ用ヘッダー*/

.glbHeader {
  position: relative;
  background-color: $c-black_2;
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include mq() {
//    position: sticky;
    position: fixed;
    z-index: 500;
  }
  &__logo {
    display: block;
    position: absolute;
    top: 10px;
    left: 30px;
    height: 50px;
//    background-color: #f0f;
    transition: .3s;
    & img {
      display: block;
      height: 100%;
    }
    &:hover {
      transition: .3s;
      opacity: .7;
    }
  }
} /*グルーバルページ用ヘッダー*/
































































