@charset "UTF-8";

@import 'default/_reset.scss';     //リセットCSS
@import 'default/_base.scss';      //ベースCSS
@import 'default/_font.scss';      //フォントCSS
@import 'default/_util.scss';      //ユーティリティCSS
@import 'default/_function.scss';  //ファンクションCSS

// ******************************
// [Sass001] メディアクエリ関数
// ******************************
$pc  : 1000px;    //パソコン用サイズ
$sp  : 800px;     //スマートフォン用サイズ
$max : max-width; //メディアクエリ用サイズ指定
$min : min-width; //メディアクエリ用サイズ指定
@mixin mq( $size: $max, $breakPoint: $sp ) {
  @media screen and ( $size : $breakPoint ) {
    @content;
  }
} //メディアクエリ関数

$c-white   : #ffffff;
$c-blue    : #001c58;
$c-black   : #000000;
$c-black_2 : #040404;
$c-black_3 : #010101; // 薄いテキストブラック
$c-red     : #9c0048;
$c-gray    : #e1e1e1; // 薄いほう
$c-gray_2  : #cacaca; // 濃いほう
$c-gray_3  : #cccccc; // ボーダー用



///////////////////////////////////
///////////////////////////////////
//あとで消すスタイル

//.demoLInks {
//  background-color: #ff0;
//  display: flex;
//                                    display: none;
//  justify-content: flex-start;
//  flex-wrap: wrap;
//  position: absolute;
//  bottom: 0;
//  right: 0;
//  width: 500px;
//  z-index: 10000;
//  border-top: 7px solid #f00;
//  border-bottom: 7px solid #f00;
//  margin-bottom: 100px;
//  & p {
//    font-weight: 600;
//    & span {
//      color: #f00;
//    }
//    &::after {
//      content: "|";
//      display: inline-block;
//      margin: 0 10px;
//    }
//  }
//  & a {
//    display: inline-block;
//    color: #333;
//    &::after {
//      content: "|";
//      display: inline-block;
//      margin: 0 10px;
//    }
//    &:hover {
//      color: #9d99ff;
//      text-decoration: underline;
//    }
//  }
//}

///////////////////////////////////
///////////////////////////////////




/* ****************************** */
/* [001] 基本設定 */
/* ****************************** */
* {
  box-sizing: border-box;
}
body {
  background-color: $c-gray;
  position: relative;
  font-size: 14px;
  .topPage & {
    font-size: 16px;
  }
//  overflow: hidden;
  @include mq($min, $pc) {
//                              background-color: #f00;
  } //レスポンシブ確認用。あとで消します。
}
.wrapper {
  width: 100%;
  overflow: hidden;
}

picture {
  & img {
    display: block;
  }
}



.goTopBtn {
  width: 80px;
  height: 80px;
  position: fixed;
//          display: none;
//  right: 10px;
//  bottom: 10px;
        right: 0;
        bottom: 0;
//  background-color: #fff;
//  border-radius: 50%;
  box-shadow: 0 1px 3px #333;
  text-align: center;
  z-index: 300;
  opacity: .8;
  transition: .3s;
//      transform: translateX(100px);

  &:hover {
    background-color: #fff;
    opacity: 1;
    transition: .3s;
  }
  &:active {
    box-shadow: none;
    transform: translateY(-3px);
    transition: .3s;
  }
  & a {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
//    background-color: #fff;
        background-color: #333;
    width: 80px;
    height: 80px;
    border-radius: inherit;
    color: #fff;
    &::after {
      content: "";
      display: block;
      width: 30px;
      height: 30px;
      margin-top: 15px;
      border: 0;
//      border-top: 3px solid #333;
//      border-left: 3px solid #333;
      border-top: 3px solid #fff;
      border-left: 3px solid #fff;
      color: #333;
      transform: rotate(45deg);
    }
  }
} /* トップへ戻るボタン */




/* ****************************** */
/* [002] 汎用コンポーネント */
/* ****************************** */
.imgBtn {
  display: block;
  max-width: 600px;
  max-height: 80px;
  margin: 0 auto;
  @include hoverOpacity();
  & + & {
    margin-top: 20px;
    @include mq() {
      margin-top: 100px;
    }
  }
  & picture {
    display: block;
    width: 100%;
    & img,
    & source {
      display: block;
      width: 100%;
    }
  }
  @include mq() {
//    margin-bottom: 100px;
    margin-bottom: 0px;
    &:last-of-type {
      margin-bottom: 100px;
    }
    .salesPage .card & {
      margin-bottom: 0px;
    }
    .businessPage & {
      margin: 0 25px 100px;
    }
  }
} /* main要素の下の方にある紺色ボタン共通class */

.catchphrase {
  max-width: 720px;
  margin: 0 auto 60px;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  @include mq() {
    text-align: left;
    .businessPage & {
      margin: 0 25px 60px;
      & br {
        display: none;
      }
    }
    .contactPage & {
      display: none;
    }
  }
}

.boldText {
  font-size: 16px;
  font-weight: 600;
} /* 太字かつ少し大きめのテキスト */



/* ****************************** */
/* [003] ドロワーメニュー */
/* ****************************** */
@import 'parts/_drawer-menu.scss'; //分割パーツSass（ドロワーメニュー）

/* ****************************** */
/* [003] グローバルヘッダー */
/* ****************************** */
@import 'parts/_glb-header.scss'; //分割パーツSass（グローバル・ヘッダー）

/* ****************************** */
/* [004] グローバルナビゲーション */
/* ****************************** */
@import 'parts/_glb-navi.scss'; //分割パーツSass（グローバル・ナビゲーション）

/* ****************************** */
/* [005] メインコンテンツ */
/* ****************************** */
@import 'parts/_main-cont.scss'; //分割パーツSass（メインコンテンツ）

/* ****************************** */
/* [006] サイドコンテンツ */
/* ****************************** */
@import 'parts/_side-cont.scss'; //分割パーツSass（サイドコンテンツ）

/* ****************************** */
/* [007] グローバルフッター */
/* ****************************** */
@import 'parts/_glb-footer.scss'; //分割パーツSass（グローバル・フッター）

/* ****************************** */
/* [008] その他の分割パーツ（WPプラグインなど） */
/* ****************************** */
.wp-pagenavi {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px auto;
}
.wp-pagenavi > * {
  margin: 0 !important;
  margin-right: 20px !important;
  transition: .3s;
}
.wp-pagenavi > *:last-child {
  margin-right: 0;
}
.wp-pagenavi .current {
  background-color: #001c58;
}
.wp-pagenavi .page.larger,
.wp-pagenavi .page.smaller {
  background-color: transparent;
}
.wp-pagenavi .page.larger:hover,
.wp-pagenavi .page.smaller:hover {
  transition: .3s;
  background-color: rgba(0, 28, 88, 0.5);
}

.wp-pagenavi .current,
.wp-pagenavi .page.larger,
.wp-pagenavi .page.smaller {
  display: block;
  width: 30px;
  height: 30px;
  border: 1px solid #001c58;
  border-radius: 50%;
  padding: 0;
}
.wp-pagenavi .current span,
.wp-pagenavi .page.larger span,
.wp-pagenavi .page.smaller span {
  display: block;
  border: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  margin: 0;
}
/*
.wp-pagenavi .page.larger {
  background-color: red;
}
*/
/*
.wp-pagenavi .page.larger span {
  width: 200px;
}
*/

.omission {
  display: inline-block;
  margin-left: 3px;
  font-size: 50%;
} /*投稿タイトルの省略記号*/

.ajax-loader {
  display: none !important;
}
.wpcf7-response-output.wpcf7-display-none.wpcf7-validation-errors,
.wpcf7-response-output.wpcf7-display-none.wpcf7-mail-sent-ok,
.wpcf7-response-output.wpcf7-mail-sent-ok,
.wpcf7-mail-sent-ok {
  background-color: $c-blue;
  border: 3px solid $c-blue;
  padding: 10px;
  text-align: center;
  font-size: 16px;
  color: $c-white;
}
span.wpcf7-list-item {
  margin: 0 !important;
}


/* ****************************** */
/* [mq001] メディアクエリ */
/* ****************************** */
@include mq($max, $pc) {
  
}