@charset "UTF-8";
// ******************************
// [007] グローバルフッター
// ******************************
.glbFooter {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $c-black_2;
  width: 100%;
//  height: 550px;
  padding: 5%;
  color: $c-white;
}
.footerNavi {
  width: 90%;
  max-width: 700px;
  margin: 0 auto;
  @include mq() {
    margin-bottom: 40px;
    max-width: unset;
    width: 90%;
  }
  & ul {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 auto;
    & li {
      margin-bottom: 40px;
      @include mq() {
        margin-bottom: 5px;
      }
      & + li::before {
        content: "|";
        display: inline-block;
        margin: 0 5px;
        @include mq() {
          margin: 0 .5px;
        }
      }
      &:nth-of-type(5)::before {
        @include mq() {
          display: none;
        }
      }
      & a {
        display: inline-block;
        padding: 3px 5px;
        border-radius: 15px;
        border-radius: 5px;
        color: $c-white;
        transition: .3s;
        &:hover{
          background-color: $c-white;
          color: $c-black;
          transition: .3s;
        }
        &:active {
          transition: .3s;
        }
      }
    }
  }
}
.companyInfo {
  display: flex;
  justify-content: space-between;
  justify-content: flex-start;
  align-items: flex-start;
  width: 800px;
  margin: 0 auto;
  &__logo {
    height: 120px;
    margin-right: 40px;
    @include mq() {
      height: unset;
      margin-right: 0;
      margin-bottom: 30px;
    }
  }
  &__data {
    display: block;
//      width: 50%;
    font-size: 12px;
    & p {
      font-size: 18px;
      font-weight: 600;
      @include mq() {
        text-align: center;
      }
    }
    & address {
      width: 100%;
      display: block;
      @include mq() {
        text-align: center;
      }
      & .tel {
        display: inline-block;
        position: relative;
        height: 10px;
        margin-right: 3px;
      }
      & .copyright {
        display: block;
        margin-top: 40px;
        font-size: 12px;
        @include mq() {
          margin-top: 20px;
        }
      }
      & .br {
        display: inline-block;
        @include mq() {
          display: block;
        }
        &::after {
          content: "　";
          display: block;
          @include mq() {
            content: "";
          }
        }
      }
      & .brSl {
        display: inline-block;
        @include mq() {
          display: block;
        }
        &::after {
          content: " ／ ";
          display: block;
          margin: 0 3px;
          @include mq() {
            content: "";
          }
        }
      }
    }
  }
  @include mq() {
    flex-direction: column;
    align-items: center;
    width: 300px;
  }
}
























