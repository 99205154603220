@charset "UTF-8";
/* ****************************** */
/* ベースCSS */
/* ****************************** */

body {
  font-family:
    "Noto Sans",
    "Noto Serif",
    "Noto Sans Japanese",
    "Avenir" ,
    "Open Sans" ,
    "Helvetica Neue" ,
    "Helvetica" ,
    "Arial" ,
    "Verdana" ,
    "Roboto" ,
    "游ゴシック" ,
    "Yu Gothic" ,
    "游ゴシック体" ,
    "YuGothic" ,
    "ヒラギノ角ゴ Pro W3" ,
    "Hiragino Kaku Gothic Pro" ,
    "Meiryo UI" ,
    "メイリオ" ,
    "Meiryo" ,
    "ＭＳ Ｐゴシック" ,
    "MS PGothic" ,
    sans-serif;
  font-weight: 100;
}
br {
  line-height: 1.3;
}
a,
a:focus {
  text-decoration: none;
}
*::selection,
*::-moz-selection {
  background: rgba(139, 139, 139, 0.3);
  color: #ffffff;
  text-shadow: 1px 1px 1px #000;
}
img {
  font-size: 0;
  letter-spacing: 0;
}
address {
  font-style: normal;
}
label,
button {
  cursor: pointer;
}
input,
textarea,
select,
option,
button {
  background: transparent;
  padding: 0;
  border-style: none;
  border-radius: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
//  -webkit-appearance: none;
//          appearance: none;
}
.clearfix::after {
  content: "";
  display: block;
  clear: both;
}

