@charset "UTF-8";
// ******************************
// ファンクションCSS
// ******************************

// ******************************
// [Fn001] 画像プレースホルダ関数
// ******************************
@function imgph( $width:'250', $height:'250', $title:'', $fz:'20', $bgc:'cccccc', $fc:'33333' ) {
  @if $title == '' {
    @return 'http://placehold.jp/{$fz}/{$bgc}/{$fc}/{$width}x{$height}.png';
    //「画像タイトル」がなければ「画像タイトル」を表示しない＝「画像サイズ」を表示する。
  } @else {
    @return 'http://placehold.jp/{$fz}/{$bgc}/{$fc}/{$width}x{$height}.png?text={#{$title}}';
    //「画像タイトル」があったら「画像タイトル」を表示する＝「画像サイズ」を表示しない。
  }
} // http://placehold.jp/を利用して画像サンプルを表示する関数


@mixin hoverShadow($bs: $c-black) {
  transform: translateY(3px);
  &:hover{
    box-shadow: 0 3px 3px $bs;
    transition: .3s;
    cursor: pointer;
  }
}
@mixin hoverOpacity( $o: .8) {
  transition: .3s;
  &:hover {
    opacity: $o;
    transition: .3s;
  }
}

@mixin positionTL( $top: 0, $left: 0, $zIndex: 100 ) {
  position: absolute;
  top: unset;
  right: unset;
  bottom: unset;
  left: unset;
  
  top: $top;
  left: $left;
  z-index: $zIndex;
}
@mixin positionTR( $top: 0, $right: 0, $zIndex: 100 ) {
  position: absolute;
  top: unset;
  right: unset;
  bottom: unset;
  left: unset;
  
  top: $top;
  right: $right;
  z-index: $zIndex;
}
@mixin positionBR( $bottom: 0, $right: 0, $zIndex: 100 ) {
  position: absolute;
  top: unset;
  right: unset;
  bottom: unset;
  left: unset;
  
  right: $right;
  bottom: $bottom;
  z-index: $zIndex;
}
@mixin positionBL( $bottom: 0, $left: 0, $zIndex: 100 ) {
  position: absolute;
  top: unset;
  right: unset;
  bottom: unset;
  left: unset;
  
  bottom: $bottom;
  left: $left;
  z-index: $zIndex;
}

@mixin positionCC( $top: 0, $left: 0, $bottom: 0, $right: 0, $zIndex: 100 ) {
  position: absolute;
  top: unset;
  right: unset;
  bottom: unset;
  left: unset;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
  z-index: $zIndex;
  margin: 0;
}

@mixin dotOverlay( $z: 200 ) {
  position: relative;
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $z;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAYAAABytg0kAAAAFElEQVQYV2NkYGD4z8DAwMjIAAUADikBA/gvnngAAAAASUVORK5CYII=);
    width: 100%;
    height: 100%;
  }
} //編みかけドット処理

