

.drawerBtn {
  @include positionTR( 20px, 30px, 3000 );
  width: 39px;
  height: 30px;
  outline: none;
                        display: none;
  @include mq() {
    display: block;
    position: fixed;
    filter: drop-shadow(0 0 1.5px #333);
  }
  & div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  & img {
    height: 100%;
  }
  &__open {
    display: block;
  }
  &__close {
    display: none;
  }
}



.drawerOverlay {
  @include positionTR( 0, 0, 1000 );
  background-color: rgba(51, 51, 51, 0.8);
  width: 100vw;
  height: 100%;
                      display: none;
}

.drawerMenu {
  @include positionCC( 0, 0, 0, 0, 1000 );
  @include positionTL( 300px, 0, 1000 );
  margin: auto;
  width: 100%;
  height: 500px;
  height: 100%;
  display: none;
  &__inner {
    position: fixed;
    top: 0;
    z-index: 1500;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
//    background-color: #0f1;
//    border: 1px solid #fff;
    & a {
      display: block;
      color: #fff;
      text-decoration: underline;
      font-size: 30px;
      margin-bottom: 30px;
      &:nth-last-of-type(1),
      &:nth-last-of-type(2) {
        width: 300px;
        font-size: 0;
        & img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}



