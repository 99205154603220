@charset "UTF-8";
/* ****************************** */
/* リセットCSS */
/* ****************************** */
/*
html5doctor.com Reset Stylesheet
v1.4.1
2010-03-01
Author: Richard Clark - http://richclarkdesign.com
*/
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary {
  display: block;
}

ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted #000;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

/* ****************************** */
/* ベースCSS */
/* ****************************** */
body {
  font-family: "Noto Sans", "Noto Serif", "Noto Sans Japanese", "Avenir" , "Open Sans" , "Helvetica Neue" , "Helvetica" , "Arial" , "Verdana" , "Roboto" , "游ゴシック" , "Yu Gothic" , "游ゴシック体" , "YuGothic" , "ヒラギノ角ゴ Pro W3" , "Hiragino Kaku Gothic Pro" , "Meiryo UI" , "メイリオ" , "Meiryo" , "ＭＳ Ｐゴシック" , "MS PGothic" , sans-serif;
  font-weight: 100;
}

br {
  line-height: 1.3;
}

a,
a:focus {
  text-decoration: none;
}

*::selection,
*::-moz-selection {
  background: rgba(139, 139, 139, 0.3);
  color: #ffffff;
  text-shadow: 1px 1px 1px #000;
}

img {
  font-size: 0;
  letter-spacing: 0;
}

address {
  font-style: normal;
}

label,
button {
  cursor: pointer;
}

input,
textarea,
select,
option,
button {
  background: transparent;
  padding: 0;
  border-style: none;
  border-radius: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
}

.clearfix::after {
  content: "";
  display: block;
  clear: both;
}

/* ****************************** */
/* フォントCSS */
/* ****************************** */
.u-mb10 {
  margin-bottom: 10px;
}

.u-mb20 {
  margin-bottom: 20px;
}

.u-mb30 {
  margin-bottom: 30px;
}

.u-mb40 {
  margin-bottom: 40px;
}

/* ****************************** */
/* [001] 基本設定 */
/* ****************************** */
* {
  box-sizing: border-box;
}

body {
  background-color: #e1e1e1;
  position: relative;
  font-size: 14px;
}

.topPage body {
  font-size: 16px;
}

.wrapper {
  width: 100%;
  overflow: hidden;
}

picture img {
  display: block;
}

.goTopBtn {
  width: 80px;
  height: 80px;
  position: fixed;
  right: 0;
  bottom: 0;
  box-shadow: 0 1px 3px #333;
  text-align: center;
  z-index: 300;
  opacity: .8;
  transition: .3s;
}

.goTopBtn:hover {
  background-color: #fff;
  opacity: 1;
  transition: .3s;
}

.goTopBtn:active {
  box-shadow: none;
  transform: translateY(-3px);
  transition: .3s;
}

.goTopBtn a {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #333;
  width: 80px;
  height: 80px;
  border-radius: inherit;
  color: #fff;
}

.goTopBtn a::after {
  content: "";
  display: block;
  width: 30px;
  height: 30px;
  margin-top: 15px;
  border: 0;
  border-top: 3px solid #fff;
  border-left: 3px solid #fff;
  color: #333;
  transform: rotate(45deg);
}

/* トップへ戻るボタン */
/* ****************************** */
/* [002] 汎用コンポーネント */
/* ****************************** */
.imgBtn {
  display: block;
  max-width: 600px;
  max-height: 80px;
  margin: 0 auto;
  transition: .3s;
}

.imgBtn:hover {
  opacity: 0.8;
  transition: .3s;
}

.imgBtn + .imgBtn {
  margin-top: 20px;
}

@media screen and (max-width: 800px) {
  .imgBtn + .imgBtn {
    margin-top: 100px;
  }
}

.imgBtn picture {
  display: block;
  width: 100%;
}

.imgBtn picture img,
.imgBtn picture source {
  display: block;
  width: 100%;
}

@media screen and (max-width: 800px) {
  .imgBtn {
    margin-bottom: 0px;
  }
  .imgBtn:last-of-type {
    margin-bottom: 100px;
  }
  .salesPage .card .imgBtn {
    margin-bottom: 0px;
  }
  .businessPage .imgBtn {
    margin: 0 25px 100px;
  }
}

/* main要素の下の方にある紺色ボタン共通class */
.catchphrase {
  max-width: 720px;
  margin: 0 auto 60px;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}

@media screen and (max-width: 800px) {
  .catchphrase {
    text-align: left;
  }
  .businessPage .catchphrase {
    margin: 0 25px 60px;
  }
  .businessPage .catchphrase br {
    display: none;
  }
  .contactPage .catchphrase {
    display: none;
  }
}

.boldText {
  font-size: 16px;
  font-weight: 600;
}

/* 太字かつ少し大きめのテキスト */
/* ****************************** */
/* [003] ドロワーメニュー */
/* ****************************** */
.drawerBtn {
  position: absolute;
  top: unset;
  right: unset;
  bottom: unset;
  left: unset;
  top: 20px;
  right: 30px;
  z-index: 3000;
  width: 39px;
  height: 30px;
  outline: none;
  display: none;
}

@media screen and (max-width: 800px) {
  .drawerBtn {
    display: block;
    position: fixed;
    filter: drop-shadow(0 0 1.5px #333);
  }
}

.drawerBtn div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.drawerBtn img {
  height: 100%;
}

.drawerBtn__open {
  display: block;
}

.drawerBtn__close {
  display: none;
}

.drawerOverlay {
  position: absolute;
  top: unset;
  right: unset;
  bottom: unset;
  left: unset;
  top: 0;
  right: 0;
  z-index: 1000;
  background-color: rgba(51, 51, 51, 0.8);
  width: 100vw;
  height: 100%;
  display: none;
}

.drawerMenu {
  position: absolute;
  top: unset;
  right: unset;
  bottom: unset;
  left: unset;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  margin: 0;
  position: absolute;
  top: unset;
  right: unset;
  bottom: unset;
  left: unset;
  top: 300px;
  left: 0;
  z-index: 1000;
  margin: auto;
  width: 100%;
  height: 500px;
  height: 100%;
  display: none;
}

.drawerMenu__inner {
  position: fixed;
  top: 0;
  z-index: 1500;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.drawerMenu__inner a {
  display: block;
  color: #fff;
  text-decoration: underline;
  font-size: 30px;
  margin-bottom: 30px;
}

.drawerMenu__inner a:nth-last-of-type(1), .drawerMenu__inner a:nth-last-of-type(2) {
  width: 300px;
  font-size: 0;
}

.drawerMenu__inner a:nth-last-of-type(1) img, .drawerMenu__inner a:nth-last-of-type(2) img {
  display: block;
  width: 100%;
  height: 100%;
}

.drawerMenu__inner a:last-of-type {
  margin-bottom: 0;
}

/* ****************************** */
/* [003] グローバルヘッダー */
/* ****************************** */
@keyframes naviLogoAnime {
  0% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(15px);
  }
  50% {
    transform: translateY(20px);
  }
  75% {
    transform: translateY(15px);
  }
  100% {
    transform: translateY(0px);
  }
}

.topHeader {
  position: relative;
  font-size: 0;
  color: #ffffff;
  position: relative;
}

.topHeader::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 200;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAYAAABytg0kAAAAFElEQVQYV2NkYGD4z8DAwMjIAAUADikBA/gvnngAAAAASUVORK5CYII=);
  width: 100%;
  height: 100%;
}

.topHeader__logo {
  display: block;
  position: absolute;
  top: unset;
  right: unset;
  bottom: unset;
  left: unset;
  top: 20px;
  left: 30px;
  z-index: 300;
  height: 80px;
  filter: drop-shadow(0 0 1.5px #333);
}

.topHeader__logo img {
  display: block;
  height: 100%;
}

@media screen and (max-width: 800px) {
  .topHeader__logo {
    position: fixed;
    height: 50px;
  }
}

.topHeader__video {
  width: 100%;
  height: 780px;
  object-fit: cover;
}

@media screen and (max-width: 800px) {
  .topHeader__video {
    height: 100vw;
  }
}

.topHeader__catchphrase {
  position: absolute;
  top: unset;
  right: unset;
  bottom: unset;
  left: unset;
  top: calc(50% - 120px);
  left: calc(50% - 400px);
  z-index: 300;
  height: 240px;
}

@media screen and (max-width: 800px) {
  .topHeader__catchphrase {
    position: absolute;
    top: unset;
    right: unset;
    bottom: unset;
    left: unset;
    top: calc(50% - 80px);
    left: calc(50% - 180px);
    z-index: 300;
    height: 160px;
  }
}

.topHeader__catchphrase picture {
  display: block;
  height: 100%;
}

.topHeader__catchphrase picture img {
  display: block;
  height: 100%;
}

.topHeader__catchphrase picture p {
  display: none;
}

.topHeader__scrollBtn {
  position: absolute;
  top: unset;
  right: unset;
  bottom: unset;
  left: unset;
  bottom: calc(50px + 34.5px);
  left: calc(50% - 97.5px);
  z-index: 300;
  height: 69px;
  font-size: 0;
  animation: naviLogoAnime 3s infinite linear;
}

.topHeader__scrollBtn img {
  display: block;
  height: 100%;
  transition: .3s;
}

@media screen and (max-width: 800px) {
  .topHeader__scrollBtn {
    position: absolute;
    top: unset;
    right: unset;
    bottom: unset;
    left: unset;
    bottom: 30px;
    left: calc(50% - 56.51px);
    z-index: 300;
    height: 40px;
  }
}

/*トップページ用ヘッダー*/
.glbHeader {
  position: relative;
  background-color: #040404;
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 800px) {
  .glbHeader {
    position: fixed;
    z-index: 500;
  }
}

.glbHeader__logo {
  display: block;
  position: absolute;
  top: 10px;
  left: 30px;
  height: 50px;
  transition: .3s;
}

.glbHeader__logo img {
  display: block;
  height: 100%;
}

.glbHeader__logo:hover {
  transition: .3s;
  opacity: .7;
}

/*グルーバルページ用ヘッダー*/
/* ****************************** */
/* [004] グローバルナビゲーション */
/* ****************************** */
.glbNavi {
  display: flex;
  width: 800px;
  height: 40px;
}

.topPage .glbNavi {
  position: absolute;
  top: unset;
  right: unset;
  bottom: unset;
  left: unset;
  top: 60px;
  right: 50px;
  z-index: 300;
  font-size: 16px;
}

.glbNavi__pageLink {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin-right: 30px;
}

.glbNavi__pageLink li a {
  display: block;
  color: #ffffff;
  transition: .3s;
}

.glbNavi__pageLink li a:hover {
  border-bottom: 3px solid #ffffff;
  transition: .3s;
}

.glbNavi__contactBtn {
  display: block;
  font-size: 0;
  transition: .3s;
}

.glbNavi__contactBtn + .glbNavi__contactBtn {
  margin: 0 20px;
}

.glbNavi__contactBtn img {
  display: block;
  transition: .3s;
}

.topPage .glbNavi__contactBtn img {
  transform: translateY(3px);
}

.topPage .glbNavi__contactBtn img:hover {
  box-shadow: 0 3px 3px #000000;
  transition: .3s;
  cursor: pointer;
}

@media screen and (max-width: 800px) {
  .glbNavi {
    display: none;
  }
}

/* ****************************** */
/* [005] メインコンテンツ */
/* ****************************** */
.mainCont {
  background-color: #e1e1e1;
  max-width: 1000px;
  margin: 0 auto;
}

.container--pc {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto 20px;
}

.seminarPage .container--pc {
  max-width: 100%;
  margin: 0;
}

@media screen and (max-width: 800px) {
  .container--pc {
    max-width: 640px;
    padding: 0 20px;
  }
  .businessPage .container--pc,
  .contactPage .container--pc,
  .recruitmentPage .container--pc,
  .seminarPage .container--pc {
    width: 100%;
    max-width: 100%;
    padding: 0;
  }
  .glbFooter .container--pc {
    padding: 0;
  }
}

/* PCサイズ用のコンテナ */
.newsHeadline {
  width: 100%;
  margin: 0 auto;
}

.newsHeadline__title {
  padding: 50px 0;
  text-align: center;
  font-size: 40px;
  color: #001c58;
}

.newsHeadline__link {
  display: block;
  width: 150px;
  text-decoration: underline;
  margin: 0 auto;
  font-size: 20px;
  color: #000000;
  text-align: center;
}

.newsHeadline__link::after {
  content: ">";
  display: inline-block;
  margin: 0 0 0 10px;
}

.newsList {
  margin-bottom: 40px;
}

.newsListItem {
  width: 100%;
  background-color: #ffffff;
  margin-bottom: 20px;
  transition: .3s;
  transform: translateY(3px);
}

.newsListItem:hover {
  box-shadow: 0 3px 3px #000000;
  transition: .3s;
  cursor: pointer;
}

.newsListItem a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #000000;
}

.newsListItem a time {
  padding: .3em 10px;
  margin-right: 10px;
}

.newsListItem a span {
  display: block;
  padding: .2em;
  word-break: break-word;
}

.contentsBox {
  position: relative;
  margin: 0 auto;
  width: 1000px;
  height: 520px;
  margin-bottom: 60px;
  font-size: 0;
}

@media screen and (max-width: 800px) {
  .contentsBox {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    margin-bottom: 100px;
  }
}

.contentsBox__title {
  height: 45px;
}

@media screen and (max-width: 800px) {
  ._merit .contentsBox__title {
    height: 100px;
  }
}

.contentsBox__title img {
  display: block;
  height: 100%;
}

.contentsBox__title span {
  display: none;
}

._right .contentsBox__title {
  position: absolute;
  top: unset;
  right: unset;
  bottom: unset;
  left: unset;
  top: 0;
  left: 0;
  z-index: 200;
}

@media screen and (max-width: 800px) {
  ._right .contentsBox__title {
    position: absolute;
    top: unset;
    right: unset;
    bottom: unset;
    left: unset;
    top: 10px;
    left: 20px;
    z-index: 200;
  }
}

._left .contentsBox__title {
  position: absolute;
  top: unset;
  right: unset;
  bottom: unset;
  left: unset;
  top: 0;
  right: 0;
  z-index: 200;
}

@media screen and (max-width: 800px) {
  ._left .contentsBox__title {
    position: absolute;
    top: unset;
    right: unset;
    bottom: unset;
    left: unset;
    top: 10px;
    right: 20px;
    z-index: 200;
  }
}

.contentsBox__text {
  width: 240px;
  font-size: 16px;
}

._right .contentsBox__text {
  position: absolute;
  top: unset;
  right: unset;
  bottom: unset;
  left: unset;
  top: 100px;
  left: 0;
  z-index: 200;
}

@media screen and (max-width: 800px) {
  ._right .contentsBox__text {
    order: 2;
    position: static;
    width: 90%;
    margin: 20px auto;
  }
}

._left .contentsBox__text {
  position: absolute;
  top: unset;
  right: unset;
  bottom: unset;
  left: unset;
  top: 100px;
  right: 0;
  z-index: 200;
}

@media screen and (max-width: 800px) {
  ._left .contentsBox__text {
    order: 2;
    position: static;
    width: 90%;
    margin: 20px auto;
  }
}

.contentsBox__text span {
  display: block;
}

.contentsBox__photo {
  display: block;
  transition: .3s;
}

@media screen and (max-width: 800px) {
  .contentsBox__photo {
    max-height: 250px;
  }
}

.contentsBox__photo picture {
  display: block;
  width: 100%;
  height: 100%;
}

.contentsBox__photo picture img {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 800px) {
  .contentsBox__photo picture img {
    object-fit: cover;
  }
}

._right .contentsBox__photo {
  position: absolute;
  top: unset;
  right: unset;
  bottom: unset;
  left: unset;
  right: 0;
  bottom: 0;
  z-index: 200;
}

@media screen and (max-width: 800px) {
  ._right .contentsBox__photo {
    order: 1;
    position: static;
    width: 100%;
    height: 350px;
    margin-top: 115px;
  }
}

._left .contentsBox__photo {
  position: absolute;
  top: unset;
  right: unset;
  bottom: unset;
  left: unset;
  bottom: 0;
  left: 0;
  z-index: 200;
}

@media screen and (max-width: 800px) {
  ._left .contentsBox__photo {
    order: 1;
    position: static;
    width: 100%;
    height: 350px;
    margin-top: 115px;
  }
}

@media screen and (max-width: 800px) {
  ._merit .contentsBox__photo {
    margin-top: 125px;
  }
}

.contentsBox__photo:hover {
  transform: scale(1.08);
  transition: .3s;
}

@media screen and (max-width: 800px) {
  .contentsBox__photo:hover {
    transform: unset;
  }
}

.contentsBox__linkBar {
  display: block;
  transition: .3s;
}

.contentsBox__linkBar:hover {
  opacity: 0.8;
  transition: .3s;
}

._right .contentsBox__linkBar {
  position: absolute;
  top: unset;
  right: unset;
  bottom: unset;
  left: unset;
  bottom: 60px;
  left: 0;
  z-index: 100;
}

@media screen and (max-width: 800px) {
  ._right .contentsBox__linkBar {
    order: 3;
    position: static;
    width: 100%;
    margin: 0 auto;
  }
  ._right .contentsBox__linkBar img {
    display: block;
    width: 90%;
    height: unset;
    margin: 0 auto;
  }
}

._left .contentsBox__linkBar {
  position: absolute;
  top: unset;
  right: unset;
  bottom: unset;
  left: unset;
  right: 0;
  bottom: 60px;
  z-index: 100;
}

@media screen and (max-width: 800px) {
  ._left .contentsBox__linkBar {
    order: 3;
    position: static;
    width: 100%;
    margin: 0 auto;
  }
  ._left .contentsBox__linkBar img {
    display: block;
    width: 90%;
    height: unset;
    margin: 0 auto;
  }
}

.contentsBox__grayBar {
  display: block;
  height: calc(100% - 25px);
}

@media screen and (max-width: 800px) {
  .contentsBox__grayBar {
    height: calc(100% - 25px);
  }
}

._right .contentsBox__grayBar {
  position: absolute;
  top: unset;
  right: unset;
  bottom: unset;
  left: unset;
  top: 50px;
  right: 350px;
  z-index: 100;
}

@media screen and (max-width: 800px) {
  ._right .contentsBox__grayBar {
    position: absolute;
    top: unset;
    right: unset;
    bottom: unset;
    left: unset;
    top: 25px;
    right: 20px;
    z-index: 0;
  }
  ._right .contentsBox__grayBar img {
    height: 100%;
  }
}

._left .contentsBox__grayBar {
  position: absolute;
  top: unset;
  right: unset;
  bottom: unset;
  left: unset;
  top: 50px;
  left: 350px;
  z-index: 100;
}

@media screen and (max-width: 800px) {
  ._left .contentsBox__grayBar {
    position: absolute;
    top: unset;
    right: unset;
    bottom: unset;
    left: unset;
    top: 25px;
    left: 20px;
    z-index: 0;
  }
  ._left .contentsBox__grayBar img {
    height: 100%;
  }
}

.contentsBox__grayBar img {
  display: block;
}

.linkBtnBox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
}

.linkBtnBox a {
  display: block;
  font-size: 0;
  margin: 0 auto;
}

.linkBtnBox._tel {
  margin-top: 40px;
}

.linkBtnBox._tel .linkBtnBox__inner h6 {
  display: block;
  height: 35px;
  margin: 0 auto 15px;
}

.linkBtnBox._tel .linkBtnBox__inner h6 span {
  display: none;
}

.linkBtnBox._tel .linkBtnBox__inner h6 picture {
  display: block;
  height: 100%;
}

.linkBtnBox._tel .linkBtnBox__inner h6 picture img {
  display: block;
  height: 100%;
  margin: 0 auto;
}

.linkBtnBox._tel .linkBtnBox__inner > span {
  display: block;
  margin-bottom: 20px;
  font-size: 24px;
  text-align: center;
}

.linkBtnBox._tel .linkBtnBox__inner > span > img {
  display: block;
  margin: 0 auto;
}

@media screen and (max-width: 800px) {
  .linkBtnBox._tel .linkBtnBox__inner > span > img {
    height: 50px;
  }
}

.linkBtnBox._tel .linkBtnBox__inner > span > span {
  display: none;
}

.linkBtnBox._tel .linkBtnBox__inner p {
  margin-bottom: 20px;
  text-align: center;
}

@media screen and (max-width: 800px) {
  .linkBtnBox._tel .linkBtnBox__inner p {
    padding: 0 20px;
    text-align: left;
  }
}

.linkBtnBox._tel .linkBtnBox__inner a:hover {
  transition: .3s;
  opacity: 0.5;
}

.linkBtnBox._recruitment {
  background-color: #000000;
  width: 100%;
  color: #ffffff;
  margin: unset;
  margin-top: 40px;
}

.linkBtnBox._recruitment a:hover {
  background-color: #4d4d4d;
}

.linkBtnBox._contact {
  background-color: transparent;
  width: 100%;
  margin-top: 0;
}

.linkBtnBox._contact a img:hover {
  transition: .3s;
  opacity: 0.3;
}

@media screen and (max-width: 800px) {
  .linkBtnBox._contact {
    height: 252px;
  }
}

@media screen and (max-width: 800px) {
  .linkBtnBox a {
    width: 90%;
  }
  .linkBtnBox a picture {
    display: block;
    width: 100%;
    margin: 0 auto;
  }
  .linkBtnBox a picture img {
    display: block;
    width: 100%;
  }
}

.mainVisualB {
  position: relative;
  width: 100%;
  height: 300px;
  margin-bottom: 50px;
  position: relative;
}

.mainVisualB::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 200;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAYAAABytg0kAAAAFElEQVQYV2NkYGD4z8DAwMjIAAUADikBA/gvnngAAAAASUVORK5CYII=);
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 800px) {
  .mainVisualB {
    height: unset;
    margin-top: 70px;
  }
}

.mainVisualB__title {
  display: none;
}

.mainVisualB__visualImg {
  display: block;
}

.mainVisualB__visualImg img {
  display: block;
  object-fit: cover;
  width: 100%;
  height: 300px;
}

@media screen and (max-width: 800px) {
  .mainVisualB__visualImg img {
    height: 100%;
  }
}

.mainVisualB__titleImg {
  display: block;
  position: absolute;
  top: unset;
  right: unset;
  bottom: unset;
  left: unset;
  top: calc(50% - 35px);
  left: calc(50% - 257.469px);
  z-index: 300;
  height: 70px;
}

.mainVisualB__titleImg img {
  height: 100%;
}

@media screen and (max-width: 800px) {
  .mainVisualB__titleImg {
    position: absolute;
    top: unset;
    right: unset;
    bottom: unset;
    left: unset;
    top: calc(50% - 30px);
    left: calc(50% - 202.297px);
    z-index: 300;
    height: 55px;
  }
}

.mainVisualB__rightNowLink {
  display: block;
  background: url(../res/img/common/btn-right-now.png) center center/360px no-repeat;
  width: 360px;
  height: 70px;
  position: absolute;
  bottom: 20px;
  left: calc(50% - 180px);
  z-index: 500;
  transition: .3s;
}

@media screen and (max-width: 800px) {
  .mainVisualB__rightNowLink {
    bottom: 40px;
    width: 200px;
    background-size: 200px;
    left: calc(50% - 100px);
    bottom: 20px;
  }
}

.mainVisualB__rightNowLink:hover {
  transform: scale(1.05);
  transition: .3s;
}

.mainVisualB__rightNowLink:active {
  transition: .3s;
  transform: translateY(5px);
}

.mainVisualC {
  position: relative;
  max-width: 1000px;
  height: 489px;
  margin: 50px auto 40px;
}

@media screen and (max-width: 800px) {
  .mainVisualC {
    width: 100%;
    height: 300px;
    height: unset;
    margin-top: 70px;
  }
}

.mainVisualC__title {
  display: none;
}

.mainVisualC .titleImgWrapperr {
  position: absolute;
  top: unset;
  right: unset;
  bottom: unset;
  left: unset;
  top: 0;
  left: 0;
  z-index: 300;
  background-color: transparent;
  width: 100%;
  max-width: 1000px;
  height: 100%;
}

@media screen and (max-width: 800px) {
  .mainVisualC .titleImgWrapperr {
    position: static;
    max-width: 640px;
    padding: 0 40px;
    margin: 0 auto;
    background-color: #f01;
  }
}

.mainVisualC .titleImgWrapperr .mainVisualC__titleImg {
  height: 150px;
  position: absolute;
  top: unset;
  right: unset;
  bottom: unset;
  left: unset;
  top: 65px;
  left: -1440px;
  z-index: 300;
}

@media screen and (max-width: 800px) {
  .mainVisualC .titleImgWrapperr .mainVisualC__titleImg {
    position: absolute;
    top: unset;
    right: unset;
    bottom: unset;
    left: unset;
    top: 30px;
    left: -460px;
    z-index: 300;
    height: 80px;
  }
}

.mainVisualC .titleImgWrapperr .mainVisualC__titleImg img {
  display: block;
  height: 100%;
}

.groupPage .mainVisualC .titleImgWrapperr .mainVisualC__titleImg {
  position: absolute;
  top: unset;
  right: unset;
  bottom: unset;
  left: unset;
  top: 65px;
  left: -1380px;
  z-index: 300;
}

@media screen and (max-width: 800px) {
  .groupPage .mainVisualC .titleImgWrapperr .mainVisualC__titleImg {
    position: absolute;
    top: unset;
    right: unset;
    bottom: unset;
    left: unset;
    top: 30px;
    left: -460px;
    z-index: 300;
    height: 80px;
  }
}

.mainVisualC__mainImg {
  position: absolute;
  top: unset;
  right: unset;
  bottom: unset;
  left: unset;
  top: 0;
  left: calc(50% - 400px);
  z-index: 200;
}

@media screen and (max-width: 800px) {
  .mainVisualC__mainImg {
    position: static;
    background-color: #ff0;
    margin: 0 auto;
  }
  .mainVisualC__mainImg img {
    object-fit: cover;
    width: 100%;
    height: 440px;
  }
}

@media screen and (max-width: 800px) and (max-width: 800px) {
  .mainVisualC__mainImg img {
    height: 100%;
  }
}

.mainVisualC__square {
  position: absolute;
  top: unset;
  right: unset;
  bottom: unset;
  left: unset;
  top: calc(244.5px - 100px);
  right: -50px;
  z-index: 100;
}

@media screen and (max-width: 800px) {
  .mainVisualC__square {
    position: absolute;
    top: unset;
    right: unset;
    bottom: unset;
    left: unset;
    right: 0;
    bottom: 30px;
    z-index: 200;
  }
  .mainVisualC__square img {
    max-height: 56px;
  }
}

.mainVisualC__gray {
  position: absolute;
  top: unset;
  right: unset;
  bottom: unset;
  left: unset;
  bottom: 40px;
  left: -50px;
  z-index: 100;
}

@media screen and (max-width: 800px) {
  .mainVisualC__gray {
    position: static;
    position: absolute;
    top: unset;
    right: unset;
    bottom: unset;
    left: unset;
    right: 11.19px;
    bottom: 30px;
    z-index: 100;
  }
  .mainVisualC__gray img {
    max-height: 56px;
  }
}

.mainVisualD {
  position: relative;
  width: 600px;
  height: 600px;
  margin: 50px auto 50px;
  overflow: hidden;
}

@media screen and (max-width: 800px) {
  .mainVisualD {
    margin: 0 0 50px;
    width: 100%;
    height: unset;
  }
}

.mainVisualD__visualImg {
  display: block;
  height: 100%;
}

@media screen and (max-width: 800px) {
  .mainVisualD__visualImg {
    position: static;
    margin-top: 70px;
    width: 100%;
  }
}

.mainVisualD__visualImg img {
  height: 100%;
  margin: 0 auto;
}

@media screen and (max-width: 800px) {
  .mainVisualD__visualImg img {
    height: 50vh;
    min-height: 300px;
  }
}

.wpr {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto 40px;
}

@media screen and (max-width: 800px) {
  .wpr {
    max-width: 640px;
    padding: 0 40px;
  }
  .businessPage .wpr,
  .contactPage .wpr,
  .recruitmentPage .wpr {
    width: 100%;
    max-width: 100%;
    padding: 0;
  }
}

.newsBox {
  padding: 20px;
  margin-bottom: 100px;
}

.newsBox .newsItem {
  padding: 40px 0;
  border-bottom: 1px solid #cccccc;
}

.newsBox .newsItem:first-of-type {
  border-top: 1px solid #cccccc;
}

.newsBox .newsItem__date {
  display: block;
  margin-bottom: 20px;
  color: #666666;
}

.newsBox .newsItem__title {
  display: inline-block;
  color: #000000;
  text-decoration: underline;
  transition: .3s;
}

.newsBox .newsItem__title:hover {
  color: #9c0048;
  transition: .3s;
}

.postContents {
  max-width: 800px;
  margin: 0 auto 60px;
  /* 投稿記事のタイトル */
  /* 投稿記事のコンテンツ */
}

@media screen and (max-width: 800px) {
  .postContents {
    max-width: 560px;
  }
}

.postContents__title {
  font-size: 24px;
  color: #001c58;
  margin-bottom: 30px;
}

.postContents__content {
  line-height: 2;
}

/* 投稿記事 */
.newsArticle {
  max-width: 1000px;
  padding: 100px;
  border: 1px solid #cccccc;
  margin: 0 auto 100px;
}

@media screen and (max-width: 800px) {
  .newsArticle {
    padding: 50px 35px;
  }
}

.newsArticle__date {
  display: block;
  margin-bottom: 40px;
  color: #010101;
}

.newsArticle__title {
  font-size: 24px;
  margin-bottom: 40px;
}

.newsArticle__content {
  color: #010101;
  line-height: 1.7;
}

.newsArticle__content p {
  margin-bottom: 35px;
}

.newsArticle__content p:last-of-type {
  margin-bottom: 0;
}

.privacypolicyList {
  max-width: 800px;
  margin: 0 auto 100px;
}

.privacypolicyList dt {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
}

.privacypolicyList dd {
  line-height: 28px;
  margin-bottom: 40px;
}

.privacypolicyList dd ul {
  list-style: disc;
  margin-left: 1.5em;
}

.introduction {
  width: 100%;
  margin: 0 auto 40px;
  text-align: center;
}

@media screen and (max-width: 800px) {
  .introduction {
    max-width: 560px;
  }
}

.introduction h2 {
  font-size: 24px;
  color: #001c58;
  margin-bottom: 30px;
}

.introduction h2 + span {
  display: block;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  margin: 0 auto 25px;
}

@media screen and (max-width: 800px) {
  .introduction h2 + span {
    text-align: left;
  }
}

.introduction p {
  font-size: 18px;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 18px;
}

@media screen and (max-width: 800px) {
  .introduction p {
    line-height: 1.4;
    text-align: left;
  }
  .business_5Page .introduction p {
    text-align: center;
  }
  .introduction p br {
    display: none;
  }
}

.introduction span {
  display: block;
  max-width: 600px;
  margin: 0 auto;
  font-size: 18px;
  line-height: 30px;
  text-align: left;
}

.recruitmentPage .introduction {
  margin-bottom: 60px;
}

@media screen and (max-width: 800px) {
  .recruitmentPage .introduction h2 {
    margin: 0 25px;
  }
}

@media screen and (max-width: 800px) {
  .recruitmentPage .introduction span {
    margin: 0 25px;
  }
}

.recruitmentPage .introduction p {
  max-width: 600px;
  font-size: 14px;
  font-weight: 100;
  line-height: 26px;
  margin: 0 auto;
}

@media screen and (max-width: 800px) {
  .recruitmentPage .introduction p {
    margin: 0 25px;
    text-align: left;
  }
}

.bizPhoto {
  width: 100%;
  margin: 0 auto;
}

.business_1Page .bizPhoto {
  max-width: 600px;
  margin-bottom: 40px;
}

@media screen and (max-width: 800px) {
  .business_1Page .bizPhoto img {
    max-width: 100%;
  }
}

.business_2Page .bizPhoto {
  max-width: 735px;
  margin-bottom: 100px;
}

@media screen and (max-width: 800px) {
  .business_2Page .bizPhoto img {
    max-width: 100%;
  }
}

.business_4Page .bizPhoto {
  position: relative;
  width: 90%;
  margin: 0 auto 100px;
}

.business_4Page .bizPhoto picture {
  display: block;
  width: 100%;
  margin: 0 auto 20px;
}

.business_4Page .bizPhoto picture img {
  display: block;
  width: 100%;
}

@media screen and (max-width: 800px) {
  .business_4Page .bizPhoto {
    width: 100%;
  }
}

.business_5Page .bizPhoto {
  max-width: 720px;
  margin-bottom: 80px;
}

@media screen and (max-width: 800px) {
  .business_5Page .bizPhoto img {
    max-width: 100%;
  }
}

.bizPhoto picture {
  display: block;
  margin-bottom: 10px;
}

.bizPhoto p {
  line-height: 24px;
}

.business_5Page .bizPhoto p {
  text-align: center;
  margin-bottom: 30px;
}

@media screen and (max-width: 800px) {
  .business_5Page .bizPhoto p {
    text-align: left;
  }
}

.bizPhoto__title {
  text-align: center;
  color: #001c58;
  font-size: 24px;
  margin-bottom: 20px;
}

.bizPhoto__commentaryBox {
  background-color: rgba(0, 28, 88, 0.9);
  width: 85%;
  padding: 30px 40px;
  margin: 0 auto;
  color: #ffffff;
  transform: translateY(-50px);
}

@media screen and (max-width: 800px) {
  .bizPhoto__commentaryBox {
    width: 90%;
  }
}

.bizPhoto__commentaryBox h3 {
  margin-bottom: 20px;
  font-size: 24px;
  text-align: center;
}

.bizPhoto__commentaryBox p {
  line-height: 26px;
}

.business3List {
  background-color: #f00;
  background-color: #ffffff;
  width: 100%;
  counter-reset: num;
}

.business3List p {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
  height: 50px;
  color: #ffffff;
  font-size: 24px;
  text-align: center;
}

.business3List ol {
  font-size: 18px;
  max-width: 1000px;
  margin-bottom: 100px;
}

.business3List ol li {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  border-bottom: 1px solid #dfdfdf;
}

.business3List ol li span {
  display: block;
  width: 260px;
}

.business3List ol li span::before {
  counter-increment: num;
  content: counter(num) ".";
  display: inline-block;
  padding-right: 2px;
}

.businessBox {
  display: block;
  position: relative;
  background-color: #001c58;
  max-width: 800px;
  height: 200px;
  margin: 0 auto 30px;
}

@media screen and (max-width: 800px) {
  .businessBox {
    width: 10%;
    margin-bottom: 60px;
    width: 100%;
    height: unset;
  }
}

.businessBox:nth-of-type(5) {
  margin-bottom: 100px;
}

@media screen and (max-width: 800px) {
  ._left .businessBox__visual,
  ._right .businessBox__visual {
    display: block;
    position: static;
    width: 100%;
  }
  ._left .businessBox__visual img,
  ._right .businessBox__visual img {
    display: block;
    width: 100%;
    height: 280px;
    object-fit: cover;
  }
}

._left .businessBox__visual {
  position: absolute;
  top: unset;
  right: unset;
  bottom: unset;
  left: unset;
  top: 0;
  left: 0;
  z-index: 100;
}

._right .businessBox__visual {
  position: absolute;
  top: unset;
  right: unset;
  bottom: unset;
  left: unset;
  top: 0;
  right: 0;
  z-index: 100;
}

.businessBox__inner {
  width: 300px;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #ffffff;
}

.businessBox__inner h3 {
  width: 210px;
  margin-bottom: 20px;
  font-size: 24px;
  text-align: center;
}

@media screen and (max-width: 800px) {
  .businessBox__inner h3 {
    width: unset;
  }
}

.businessBox__inner p {
  width: 210px;
  line-height: 26px;
}

@media screen and (max-width: 800px) {
  .businessBox__inner p {
    width: unset;
    text-align: left;
  }
}

@media screen and (max-width: 800px) {
  ._left .businessBox__inner,
  ._right .businessBox__inner {
    position: static;
    width: 100%;
    padding: 40px;
    margin: 0 auto;
    text-align: center;
  }
}

._left .businessBox__inner {
  position: absolute;
  top: unset;
  right: unset;
  bottom: unset;
  left: unset;
  top: 0;
  right: 0;
  z-index: 100;
  padding-right: 80px;
  padding-left: 10px;
}

._right .businessBox__inner {
  position: absolute;
  top: unset;
  right: unset;
  bottom: unset;
  left: unset;
  top: 0;
  left: 0;
  z-index: 100;
  padding-left: 80px;
  padding-right: 10px;
}

._left .businessBox__btn {
  position: absolute;
  top: unset;
  right: unset;
  bottom: unset;
  left: unset;
  top: calc(50% - 15px);
  right: 20px;
  z-index: 200;
}

@media screen and (max-width: 800px) {
  ._left .businessBox__btn {
    position: absolute;
    top: unset;
    right: unset;
    bottom: unset;
    left: unset;
    top: 0;
    right: 0;
    z-index: 100;
  }
}

._right .businessBox__btn {
  position: absolute;
  top: unset;
  right: unset;
  bottom: unset;
  left: unset;
  top: calc(50% - 15px);
  left: 20px;
  z-index: 200;
}

@media screen and (max-width: 800px) {
  ._right .businessBox__btn {
    position: absolute;
    top: unset;
    right: unset;
    bottom: unset;
    left: unset;
    top: 0;
    right: 0;
    z-index: 100;
  }
}

.businessBox:hover {
  transition: .3s;
}

.businessBox:hover:hover {
  opacity: 0.8;
  transition: .3s;
}

/* 各ビジネス個別ページへのリンクボックス */
.messageBox {
  max-width: 800px;
  height: 200px;
  background-color: #a0a0a0;
  color: #ffffff;
  font-size: 35px;
  line-height: 50px;
  font-weight: 600;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 30px;
}

.annotationBox {
  max-width: 800px;
  font-size: 16px;
  line-height: 28px;
  margin: 0 auto 30px;
}

.cardContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1000px;
}

.cardContainer._immigration {
  margin-bottom: 40px;
}

@media screen and (max-width: 800px) {
  .cardContainer._immigration .card {
    width: 100%;
  }
}

.cardContainer._immigration .card .card__thum {
  width: 100%;
}

.cardContainer._immigration .card .card__thum img {
  display: block;
  width: 100%;
}

.cardContainer._immigration .card__inner {
  padding: 20px;
}

.cardContainer._immigration .card__inner h3 {
  color: #001c58;
}

.cardContainer._immigration .card .imgBtn {
  margin-bottom: 0;
}

.cardContainer._sales {
  margin-bottom: 100px;
}

.cardContainer._sales .card {
  padding: 20px 20px 30px;
}

@media screen and (max-width: 800px) {
  .cardContainer._sales .card {
    width: 100%;
    padding: 30px;
  }
}

.cardContainer._sales .card__thum {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 260px;
  height: 260px;
  overflow: hidden;
  position: relative;
  margin: 0 auto 20px;
}

@media screen and (max-width: 800px) {
  .cardContainer._sales .card__thum {
    width: 200px;
    height: 200px;
  }
}

.cardContainer._sales .card__thum img {
  width: 100%;
}

.cardContainer._sales .card__inner h3 {
  text-align: center;
}

.cardContainer._sales .card__inner picture img,
.cardContainer._sales .card__inner picture source {
  margin: 0 auto;
}

/* カード格納用コンテナ */
.card {
  background-color: #ffffff;
  width: 300px;
  box-shadow: 0 3px 3px #999999;
  margin-bottom: 30px;
  transition: .3s;
}

.card:hover {
  box-shadow: none;
  transition: .3s;
  transform: translateY(-3px);
}

.card__thum img {
  display: block;
}

.card__inner {
  width: 100%;
}

.card__inner h3 {
  height: 50px;
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 28px;
}

.card__inner p {
  margin-bottom: 20px;
  line-height: 24px;
}

.card__inner a img {
  display: block;
  margin: 20px auto;
  margin-bottom: 27px;
  transition: .3s;
  transition: .3s;
}

.card__inner a img:hover {
  opacity: 0.8;
  transition: .3s;
}

.card__inner a img:hover {
  transform: translateX(3px);
}

/* 投稿記事用カード */
.postPager {
  width: 100%;
  margin-bottom: 100px;
  display: flex;
  justify-content: center;
}

.postPager a {
  display: block;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  border: 1px solid #001c58;
  margin-right: 12px;
  transition: .3s;
}

.postPager a:last-of-type {
  margin-right: 0;
}

@media screen and (max-width: 800px) {
  .postPager a {
    width: 24px;
    height: 24px;
  }
}

.postPager__this {
  background-color: #001c58;
}

.postPager__more {
  background-color: transparent;
}

.postPager__more:hover {
  transition: .3s;
  background-color: #001c58;
}

/* 投稿ループ用ページャ */
.goMerit {
  background-color: #cfc4b3;
  width: 800px;
  padding: 30px 40px;
  margin: 0 auto 80px;
  text-align: center;
}

@media screen and (max-width: 800px) {
  .goMerit {
    width: 100%;
    padding: 15px 20px;
  }
}

.goMerit p {
  margin: 0 auto 18px;
  font-size: 18px;
  line-height: 30px;
}

.goMerit p .br {
  display: none;
}

@media screen and (max-width: 800px) {
  .goMerit p .br {
    display: block;
  }
}

.goMerit a {
  display: block;
  max-width: 600px;
  margin: 0 auto;
  transition: .3s;
}

.goMerit a:hover {
  opacity: 0.7;
  transition: .3s;
}

@media screen and (max-width: 800px) {
  .goMerit a {
    max-width: 300px;
  }
}

.goMerit a img {
  width: 100%;
  display: block;
}

/* メリットへのリンクボックス */
.salesTitle {
  font-size: 40px;
  color: #001c58;
  max-width: 800px;
  text-align: center;
  margin: 0 auto 30px;
}

@media screen and (max-width: 800px) {
  .salesTitle {
    text-align: left;
  }
}

.salesAddress {
  max-width: 560px;
  margin: 0 auto 30px;
  font-weight: 600;
  line-height: 26px;
}

.salesHr {
  max-width: 1000px;
}

.salesHr._s {
  border: 1px solid #000000;
}

@media screen and (max-width: 800px) {
  .salesHr._s {
    margin-bottom: 40px;
  }
}

.salesHr._m {
  border: 3px solid #000000;
  margin-bottom: 60px;
}

.salesText {
  max-width: 560px;
  margin: 0 auto 30px;
  line-height: 26px;
}

@media screen and (max-width: 800px) {
  .salesText {
    margin-bottom: 50px;
  }
}

.salesData {
  display: flex;
  justify-content: space-between;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 700px;
  margin: 0 auto 40px;
}

@media screen and (max-width: 800px) {
  .salesData {
    display: block;
    height: 300px;
    margin-bottom: 80px;
  }
}

.salesData dl {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #cfc4b3;
  width: 220px;
  height: 90px;
  text-align: center;
}

.salesData dl + dl {
  margin-left: 20px;
}

@media screen and (max-width: 800px) {
  .salesData dl + dl {
    margin: 0 auto 20px;
  }
}

@media screen and (max-width: 800px) {
  .salesData dl {
    width: 400px;
    margin: 0 auto 20px;
  }
}

.salesData dl dt {
  width: 120px;
  font-size: 16px;
  margin-bottom: 10px;
  color: #cfc4b3;
  background-color: #ffffff;
  border-radius: 3px;
}

@media screen and (max-width: 800px) {
  .salesData dl dt {
    width: 180px;
  }
}

.salesData dl dd {
  font-size: 24px;
  color: #ffffff;
}

.salesData dl:nth-of-type(1) dd::before {
  content: "¥";
  display: inline-block;
  padding-right: 5px;
}

.salesData dl:nth-of-type(2) dd span:nth-of-type(2) sup {
  font-size: 50%;
  vertical-align: super;
}

.salesGallery {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  max-width: 700px;
  margin: 0 auto calc(60px - 20px);
  font-size: 0;
}

@media screen and (max-width: 800px) {
  .salesGallery {
    max-width: 560px;
    margin-bottom: 100px;
  }
}

.salesGallery a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 160px;
  margin-bottom: 20px;
  margin-right: 15px;
  overflow: hidden;
}

@media screen and (max-width: 800px) {
  .salesGallery a {
    max-width: 250px;
    max-height: 250px;
    width: 125px;
    height: 125px;
    margin-right: 0;
  }
}

.salesGallery a:hover {
  opacity: .8;
}

.salesGallery a img {
  display: block;
  width: 320px;
}

/* 投稿に紐付いた複数画像ギャラリー */
.details {
  margin-bottom: 90px;
}

.details__title {
  margin-bottom: 30px;
  font-size: 24px;
  text-align: center;
}

.details__list {
  max-width: 600px;
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
  margin: 0 auto;
}

.details__list dt {
  background-color: #c9c9c9;
  width: 110px;
  padding: .2em .5em;
}

.details__list dt:nth-of-type(2n) {
  background-color: #e1e1e1;
}

.details__list dd {
  background-color: #c9c9c9;
  width: calc(100% - 110px);
  padding: .2em;
}

.details__list dd::before {
  content: "：";
  display: inline-block;
  text-indent: -1em;
}

.details__list dd:nth-of-type(2n) {
  background-color: #e1e1e1;
}

.details__hr {
  width: 60px;
  border: 1px solid #000000;
  margin: 0 auto 30px;
}

/* （物件などの）詳細 */
.pointBox {
  max-width: 800px;
  margin: 0 auto 80px;
}

.groupPage .pointBox:nth-of-type(1) h2 br {
  display: none;
}

@media screen and (max-width: 800px) {
  .groupPage .pointBox:nth-of-type(1) h2 br {
    display: block;
  }
}

.groupPage .pointBox:nth-of-type(1) p {
  max-width: 600px;
  margin: 0 auto;
}

.pointBox:last-of-type {
  margin-bottom: 100px;
}

.pointBox__title {
  margin-bottom: 30px;
  font-size: 40px;
  text-align: center;
}

.groupPage .pointBox__title {
  color: #001c58;
  font-size: 24px;
}

.pointBox__hr {
  width: 60px;
  border: 1px solid #000000;
  margin: 0 auto 30px;
}

.pointBox__img {
  display: block;
  margin: 0 auto;
}

.pointBox__img img {
  width: 100%;
}

.pointBox__subPointBox {
  margin-bottom: 60px;
}

.pointBox__subPointBox h3 {
  display: block;
  margin: 0 auto 10px;
  font-size: 18px;
  text-align: center;
  color: #535353;
}

.pointBox__subPointBox span {
  display: block;
  width: 1px;
  height: 30px;
  background-color: #535353;
  border: 1px solid #535353;
  margin: 0 auto 10px;
}

.pointBox__subPointBox picture {
  display: block;
  max-width: 400px;
  margin: 0 auto 18px;
}

.pointBox__subPointBox picture img {
  display: block;
  width: 100%;
}

.pointBox__subPointBox p {
  max-width: 400px;
  line-height: 26px;
  margin: 0 auto 20px;
}

@media screen and (max-width: 800px) {
  .pointBox__subPointBox p {
    line-height: 1.5;
  }
}

.pointBox__subPointBox div {
  display: flex;
  justify-content: space-between;
  max-width: 400px;
  margin: 0 auto;
}

.pointBox__subPointBox div picture {
  display: block;
  margin: 0;
}

@media screen and (max-width: 800px) {
  .pointBox__subPointBox div picture:last-of-type {
    margin-left: 20px;
  }
}

.pointBox__subPointBox div picture img {
  display: block;
}

.pointBox__comment {
  background-color: #ffffff;
  max-width: 800px;
  padding: 60px;
  border: 1px solid #000000;
  margin: 0 auto;
}

.pointBox__comment p {
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 40px;
}

.pointBox__comment p:last-of-type {
  text-align: right;
  font-weight: 600;
  margin-bottom: 0;
}

@media screen and (max-width: 800px) {
  .pointBox__comment p {
    line-height: 1.4;
  }
}

@media screen and (max-width: 800px) {
  .pointBox__comment {
    padding: 15px;
  }
}

.pointBox__comment dl {
  font-size: 16px;
}

.pointBox__comment dl dt {
  margin-bottom: 10px;
  font-weight: 800;
}

.pointBox__comment dl dd {
  margin-bottom: 40px;
  line-height: 28px;
}

.pointBox__comment dl dd:last-of-type {
  margin-bottom: 0;
}

.pointBox__list {
  max-width: 600px;
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
  margin: 0 auto;
}

.pointBox__list dt {
  background-color: #c9c9c9;
  width: 110px;
  padding: .2em .5em;
}

.pointBox__list dt:nth-of-type(2n) {
  background-color: #e1e1e1;
}

.pointBox__list dd {
  background-color: #c9c9c9;
  width: calc(100% - 110px);
  padding: .2em;
}

.pointBox__list dd::before {
  content: "：";
  display: inline-block;
  text-indent: -1em;
}

.pointBox__list dd:nth-of-type(2n) {
  background-color: #e1e1e1;
}

.pointBox__address {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}

@media screen and (max-width: 800px) {
  .pointBox__address {
    flex-direction: column;
  }
}

.pointBox__address picture {
  height: 255px;
  margin-right: 20px;
}

@media screen and (max-width: 800px) {
  .pointBox__address picture {
    order: 2;
    width: 100%;
    height: unset;
  }
}

.pointBox__address picture img {
  display: block;
  width: 100%;
}

.pointBox__address address {
  display: block;
  width: 70%;
}

@media screen and (max-width: 800px) {
  .pointBox__address address {
    order: 1;
    width: 100%;
  }
}

.pointBox__address address p {
  display: block;
  line-height: 26px;
  margin-bottom: 30px;
}

.pointBox__address address p:first-of-type {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
}

.pointBox__address address p .br {
  display: none;
}

@media screen and (max-width: 800px) {
  .pointBox__address address p .br {
    display: block;
  }
}

/* 会社の活動紹介ボックス */
.recruitmentIntroduction {
  max-width: 600px;
  margin: 0 auto 80px;
}

@media screen and (max-width: 800px) {
  .recruitmentIntroduction {
    max-width: 560px;
  }
}

.recruitmentIntroduction h3 {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
}

.recruitmentIntroduction hr {
  width: 60px;
  border: 1px solid #000000;
  margin: 0 auto 14px;
}

.recruitmentIntroduction p,
.recruitmentIntroduction address {
  max-width: 600px;
  margin: 0 auto 40px;
  text-align: center;
  line-height: 26px;
}

@media screen and (max-width: 800px) {
  .recruitmentIntroduction p {
    margin: 0 25px;
    text-align: left;
  }
}

/* イントロダクション */
.seminarPage .mainCont {
  max-width: 100%;
}

/* セミナーページ */
.seminarBox {
  margin: 0 auto 80px;
}

@media screen and (max-width: 800px) {
  .seminarBox {
    padding: 25px;
  }
  .seminarBox:nth-of-type(3) {
    width: 100%;
    padding: 0;
  }
}

.seminarBox:nth-of-type(1) .seminarBox__title {
  background-image: url(../res/img/sub/seminar_title-01.png);
  background-size: 237px 39px;
}

.seminarBox:nth-of-type(2) {
  background-color: #C3C8D4;
  padding: 30px 0;
}

.seminarBox:nth-of-type(2) .seminarBox__title {
  background-image: url(../res/img/sub/seminar_title-02.png);
  background-size: 160px 39px;
}

.seminarBox:nth-of-type(3) {
  margin-bottom: 100px;
}

.seminarBox:nth-of-type(3) .seminarBox__title {
  background-image: url(../res/img/sub/seminar_title-03.png);
  background-size: 159px 39px;
}

.seminarBoxInner {
  max-width: 800px;
  margin: 0 auto;
}

.seminarBox__title {
  background: center center no-repeat;
  height: 39px;
  margin-bottom: 30px;
  font-size: 24px;
  color: #001c58;
  text-align: center;
}

.seminarBox__title > span {
  display: none;
}

.seminarBox table {
  background-color: #ffffff;
  width: 100%;
}

.seminarBox table tr th,
.seminarBox table tr td {
  padding: 1em;
}

.seminarBox table tr th {
  width: 180px;
  max-width: 180px;
  min-width: 180px;
  border: 1px solid #e1e1e1;
  font-weight: 100;
}

@media screen and (max-width: 800px) {
  .seminarBox table tr th {
    width: 90px;
    max-width: 90px;
    min-width: 90px;
  }
}

.seminarBox table tr td {
  border: 1px solid #e1e1e1;
}

.instructorBox {
  display: flex;
  width: 100%;
  padding: 20px;
  background-color: #ffffff;
}

@media screen and (max-width: 800px) {
  .instructorBox {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: calc(100% - 50px);
    margin: 0 auto;
  }
  .instructorBox > img {
    margin-right: 0;
  }
  .instructorBox > div h3 {
    text-align: center;
  }
}

.instructorBox > img {
  display: block;
  object-fit: cover;
  width: 160px;
  height: 160px;
  margin-right: 20px;
}

.instructorBox > div {
  width: 100%;
}

.instructorBox > div h3 {
  font-size: 18px;
  margin-bottom: 20px;
}

.instructorBox > div h3 span {
  font-weight: 600;
}

.instructorBox > div p {
  line-height: 1.4;
}

.form {
  max-width: 800px;
  margin: 0 auto 100px;
}

@media screen and (max-width: 800px) {
  .form {
    max-width: 100%;
  }
}

.form._contact fieldset div legend p:nth-of-type(1) {
  display: block;
}

@media screen and (max-width: 800px) {
  .form._contact fieldset div legend p:nth-of-type(1) {
    display: none;
  }
}

.form._contact fieldset div legend p:nth-of-type(2) {
  display: none;
}

@media screen and (max-width: 800px) {
  .form._contact fieldset div legend p:nth-of-type(2) {
    display: block;
  }
}

.form._recruitment fieldset div legend p:nth-of-type(1) {
  display: block;
  text-align: center;
  margin: 0 auto;
  width: 100%;
  display: block;
}

.form._recruitment fieldset div legend p:nth-of-type(2) {
  display: block;
  text-align: center;
  margin: 0 auto;
}

@media screen and (max-width: 800px) {
  .form._recruitment fieldset div legend p:nth-of-type(2) {
    display: block;
  }
}

.form._seminar fieldset:first-of-type {
  background-color: #ffffff;
}

.form._seminar fieldset:first-of-type legend {
  background: #ffffff url(../res/img/sub/seminar_title-04.png) center center/275px 39px no-repeat;
  height: 39px;
  padding-top: 90px;
}

.form._seminar fieldset:first-of-type legend > span {
  display: none;
}

.form._seminar fieldset:first-of-type dl {
  justify-content: flex-start;
  border: 0;
}

.form._seminar fieldset:first-of-type dl dt {
  min-width: 250px;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  padding: .3em .5em;
  border: 0;
}

@media screen and (max-width: 800px) {
  .form._seminar fieldset:first-of-type dl dt {
    justify-content: flex-start;
  }
}

.form._seminar fieldset:first-of-type dl dt span {
  font-size: 18px;
}

.form._seminar fieldset:first-of-type dl dt span:first-of-type {
  order: 1;
}

@media screen and (max-width: 800px) {
  .form._seminar fieldset:first-of-type dl dt span:first-of-type {
    order: 2;
    margin-left: 10px;
  }
}

.form._seminar fieldset:first-of-type dl dt span:last-of-type {
  order: 2;
  background-color: #9c0048;
  background-color: pink;
  background: #ffffff center center no-repeat;
  background-size: 80px 30px;
  width: 80px;
  height: 30px;
  padding: 0;
  color: #ffffff;
  background-image: url(../res/img/common/btn-required-pc.png);
}

.form._seminar fieldset:first-of-type dl dt span:last-of-type.any {
  background-image: url(../res/img/common/btn-any-pc.png);
}

@media screen and (max-width: 800px) {
  .form._seminar fieldset:first-of-type dl dt span:last-of-type.any {
    order: 1;
    background-image: url(../res/img/common/btn-any-sp.png);
    background-size: 80px 32px;
  }
}

@media screen and (max-width: 800px) {
  .form._seminar fieldset:first-of-type dl dt span:last-of-type {
    order: 1;
    background-image: url(../res/img/common/btn-required-sp.png);
    background-size: 80px 32px;
  }
}

.form._seminar fieldset:first-of-type dl dt:last-of-type span:last-of-type {
  display: block;
}

.form._seminar fieldset:first-of-type dl dd {
  width: calc(100% - 250px - 20px);
  padding: .3em .5em;
  background-color: transparent;
  border: 0;
  margin-bottom: 20px;
}

@media screen and (max-width: 800px) {
  .form._seminar fieldset:first-of-type dl dd {
    width: 100%;
  }
}

.form._seminar fieldset:first-of-type dl dd input,
.form._seminar fieldset:first-of-type dl dd textarea {
  width: 100%;
  min-width: 100%;
}

.form fieldset {
  display: block;
  background-color: #e1e1e1;
  margin-bottom: 60px;
  min-inline-size: 0;
}

.form fieldset legend {
  width: 100%;
}

.form fieldset div legend {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #000000;
  width: 100%;
  margin-bottom: 30px;
  font-size: 24px;
  color: #ffffff;
  text-align: center;
}

@media screen and (max-width: 800px) {
  .form fieldset div legend {
    flex-direction: column;
    padding: .2em 1em;
  }
}

.form fieldset div legend > p:last-of-type {
  display: block;
  height: 25px;
}

.form fieldset div legend > p:last-of-type > span {
  display: block;
  position: absolute;
  top: unset;
  right: unset;
  bottom: unset;
  left: unset;
  top: calc(50% - 12.5px);
  right: 0;
  z-index: 100;
  width: 140px;
  height: 25px;
  font-size: 14px;
  color: #ffffff;
}

@media screen and (max-width: 800px) {
  .form fieldset div legend > p:last-of-type > span {
    position: static;
    width: unset;
  }
}

.form fieldset dl {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 16px;
  border: 1px solid #bfbfbf;
}

@media screen and (max-width: 800px) {
  .form fieldset dl {
    flex-direction: column;
    margin: 0 25px;
  }
}

.form fieldset dl dt {
  background-color: #d2d2d2;
  width: 200px;
  max-width: 200px;
  padding-left: 20px;
  padding-top: 15px;
  border-bottom: 1px solid #bfbfbf;
  font-weight: 600;
}

.form fieldset dl dt:last-of-type {
  border-bottom: 0;
}

@media screen and (max-width: 800px) {
  .form fieldset dl dt {
    width: 100%;
    max-width: 100%;
    text-align: center;
    padding-left: 0;
    border-bottom: 0;
  }
}

.form fieldset dl dd {
  display: block;
  max-width: calc(100% - 200px);
  width: calc(100% - 200px);
  padding: 10px 20px;
  border-bottom: 1px solid #bfbfbf;
  /*
        & .radioBox {
          display: inline-flex;
          justify-content: space-between;
          align-items: center;
          & input[type="radio"] {
            display: none;
            &:checked + label {
              background-color: $c-black;
              color: $c-white;
            }
          }
          @include mq() {
            display: flex;
            justify-content: center;
            margin: 0 auto;
          }
          & label {
            background-color: $c-white;
            width: 100px;
            height: 25px;
            border: 1px solid $c-black;
            margin-right: 10px;
            text-align: center;
            transition: .3s;
            &:last-of-type {
              margin-right: 0;
            }
            &:hover {
              transition: .3s;
              background-color: lighten($c-black, 30%);
              color: $c-white;
            }
          }
        }
        */
}

.form fieldset dl dd:last-of-type {
  border-bottom: 0;
}

@media screen and (max-width: 800px) {
  .form fieldset dl dd {
    width: 100%;
    max-width: 100%;
    background-color: #d2d2d2;
  }
}

.form fieldset dl dd .wpcf7-form-control-wrap {
  display: block;
}

.form fieldset dl dd .wpcf7-form-control-wrap + * {
  margin-top: 10px;
}

.form fieldset dl dd select {
  display: block;
  background-color: #ffffff;
  border: 1px solid #bfbfbf;
  margin-bottom: 10px;
}

.form fieldset dl dd select:focus {
  outline-color: #000000;
}

.form fieldset dl dd input[type="text"],
.form fieldset dl dd input[type="tel"],
.form fieldset dl dd input[type="email"],
.form fieldset dl dd textarea {
  display: block;
  width: 540px;
  max-width: 540px;
  padding: .2em .5em;
  border: 1px solid #bfbfbf;
}

@media screen and (max-width: 800px) {
  .form fieldset dl dd input[type="text"],
  .form fieldset dl dd input[type="tel"],
  .form fieldset dl dd input[type="email"],
  .form fieldset dl dd textarea {
    background-color: #f0f0f0;
    width: 100%;
    max-width: 100%;
  }
}

.form fieldset dl dd input[type="text"]:focus,
.form fieldset dl dd input[type="tel"]:focus,
.form fieldset dl dd input[type="email"]:focus,
.form fieldset dl dd textarea:focus {
  outline-color: #000000;
}

.form fieldset dl dd input[type="text"] + *,
.form fieldset dl dd input[type="tel"] + *,
.form fieldset dl dd input[type="email"] + *,
.form fieldset dl dd textarea + * {
  margin-top: 10px;
}

@media screen and (max-width: 800px) {
  .form fieldset dl dd input[type="text"],
  .form fieldset dl dd input[type="tel"],
  .form fieldset dl dd input[type="email"],
  .form fieldset dl dd textarea {
    background-color: #fff;
  }
}

.form fieldset dl dd .radioBox {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}

.form fieldset dl dd .radioBox > span {
  display: block;
  margin-right: 10px !important;
}

.form fieldset dl dd .radioBox > span:last-of-type {
  margin-right: 0 !important;
}

.form fieldset dl dd .radioBox > span label {
  display: block;
  background-color: #ffffff;
  max-width: 150px;
  text-align: center;
  transition: .3s;
}

.form fieldset dl dd .radioBox > span label > input[type="radio"] {
  display: none;
}

.form fieldset dl dd .radioBox > span label > input[type="radio"]:checked + span {
  background-color: #000000;
  color: #ffffff;
}

.form fieldset dl dd .radioBox > span label > span {
  display: inline-block;
  width: 100%;
  background-color: #ffffff;
  padding: .2em 1em;
  border: 1px solid #000000;
}

.form fieldset dl dd .radioBox > span label > span:hover {
  transition: .3s;
  background-color: #4d4d4d;
  color: #ffffff;
}

@media screen and (max-width: 800px) {
  .form fieldset dl dd .radioBox {
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }
}

.form fieldset dl dd .inputTextM2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

@media screen and (max-width: 800px) {
  .form fieldset dl dd .inputTextM2 {
    width: 100%;
    max-width: 100%;
  }
}

.form fieldset dl dd .inputTextM2 span {
  display: block;
  width: 100%;
}

.form fieldset dl dd .inputTextM2 input[type="text"] {
  display: inline-block;
  width: 100%;
  margin-bottom: 0;
}

.form fieldset dl dd .inputTextM2 .wpcf7-form-control-wrap.recruitment_area {
  position: relative;
}

.form fieldset dl dd .inputTextM2 .wpcf7-form-control-wrap.recruitment_area::before, .form fieldset dl dd .inputTextM2 .wpcf7-form-control-wrap.recruitment_area::after {
  position: absolute;
  display: block;
}

.form fieldset dl dd .inputTextM2 .wpcf7-form-control-wrap.recruitment_area::before {
  content: "m";
  top: calc(50% - 11px);
  top: 5px;
  right: 26px;
}

.form fieldset dl dd .inputTextM2 .wpcf7-form-control-wrap.recruitment_area::after {
  content: "2";
  top: 3px;
  right: 21px;
  font-size: 8px;
}

.form fieldset dl dd textarea {
  height: 82px;
  min-height: 82px;
  min-width: 540px;
  max-height: 50vh;
  transition: .1s;
}

@media screen and (max-width: 800px) {
  .form fieldset dl dd textarea {
    min-width: 100%;
  }
}

.form fieldset .formPrivacy {
  /*
      &__checkbox {
        display: flex;
        align-items: center;
        margin: 0 auto  30px;
        & input[type="checkbox"] {
          display: none;
          &:checked + label span:nth-of-type(1) {
            background-color: $c-black;
            &::after {
              content: "\f00c";
            }
          }
        }
        & label {
          display: inline-flex;
          padding: 5px;
          margin: 0 auto;
          & span {
            display: block;
            transition: .3s;
            &:nth-of-type(1) {
              position: relative;
              width: 25px;
              height: 25px;
              border: 1px solid $c-black;;
              margin-right: 10px;
              &::after {
                display: inline-block;
                font-family: "FontAwesome";
                font-size: 18px;
                color: #fff;
                position: absolute;
                top: calc(50% - 8px);
                left: calc(50% - 8px);
              }
            }
            &:nth-of-type(2) {
              border-bottom: 1px solid transparent;
              font-size: 18px;
              font-weight: 600;
            }
          }
        }
      }
      */
}

@media screen and (max-width: 800px) {
  .form fieldset .formPrivacy {
    max-width: 560px;
    padding: 0 25px;
    margin: 0 auto;
  }
}

.form fieldset .formPrivacy__title {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 30px;
}

.form fieldset .formPrivacy__list {
  margin-bottom: 60px;
  max-width: 600px;
  margin: 0 auto;
  list-style: none;
  counter-reset: num;
}

.form fieldset .formPrivacy__list li {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.form fieldset .formPrivacy__list li::before {
  counter-increment: num;
  content: counter(num) ".";
  display: inline-block;
  font-weight: 600;
  padding-right: 3px;
  margin-right: 10px;
}

.form fieldset .formPrivacy__list li a {
  color: #118ded;
  text-decoration: underline;
}

.form fieldset .formPrivacy__confirmation1 {
  font-weight: 600;
  text-align: center;
  margin-bottom: 30px;
}

@media screen and (max-width: 800px) {
  .form fieldset .formPrivacy__confirmation1 {
    text-align: left;
  }
}

.form fieldset .formPrivacy__checkbox {
  display: flex;
  justify-content: center;
  margin: 0 auto  30px;
  width: 100%;
}

.form fieldset .formPrivacy__checkbox .wpcf7-list-item.first.last {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.form fieldset .formPrivacy__checkbox input[type="checkbox"] {
  display: none;
}

.form fieldset .formPrivacy__checkbox input[type="checkbox"]:checked + span::before {
  background-color: #000000;
}

.form fieldset .formPrivacy__checkbox input[type="checkbox"]:checked + span::after {
  content: "\f00c";
}

.form fieldset .formPrivacy__checkbox label {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  display: block;
  position: relative;
  width: 100%;
  margin: 0 auto;
}

.form fieldset .formPrivacy__checkbox label span {
  display: block;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: .3s;
}

.form fieldset .formPrivacy__checkbox label span::before {
  content: "";
  display: inline-block;
  position: relative;
  width: 25px;
  height: 25px;
  border: 1px solid #000000;
  margin-right: 10px;
}

.form fieldset .formPrivacy__checkbox label span::after {
  display: inline-block;
  font-family: "FontAwesome";
  font-size: 18px;
  color: #fff;
  position: absolute;
  top: calc(50% - 10px);
  left: 5px;
}

.form fieldset .formPrivacy__checkbox p span {
  margin-right: 3px;
}

.form fieldset .formPrivacy__checkbox p span input[type="checkbox"] {
  background-color: red;
}

.form fieldset .formPrivacy__checkbox label {
  display: inline-block;
}

.form fieldset .formPrivacy__checkbox label span {
  font-size: 18px;
  font-weight: 600;
}

.form fieldset .formPrivacy__confirmation2 {
  margin: 0 auto 30px;
  text-align: center;
}

/* フォームパーツ */
.comingSoon {
  width: 100%;
  background-color: #001c58;
  padding: 50px 0;
  margin: 0 auto 50px;
}

.comingSoon h2 {
  margin-bottom: 20px;
  font-size: 40px;
  font-weight: 100;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
}

.comingSoon p {
  margin-bottom: 10px;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
}

.comingSoon p:last-of-type {
  margin-bottom: 0;
}

/* セミナーページが非公開の時の表示物 */
.meritIntroduction {
  margin-bottom: 55px;
}

.meritIntroduction h3 {
  font-size: 18px;
  text-align: center;
  margin-bottom: 30px;
}

.meritIntroduction p {
  max-width: 600px;
  margin: 0 auto;
  line-height: 26px;
  margin-bottom: 35px;
}

.meritIntroduction div {
  max-width: 800px;
  margin: 0 auto;
}

.meritIntroduction div h4 {
  font-size: 18px;
  color: #001c58;
  margin-bottom: 20px;
}

.meritIntroduction div h4 + picture {
  display: block;
  margin-bottom: 40px;
}

.meritIntroduction div h4 + picture img {
  display: block;
  width: 100%;
}

.meritIntroduction div h4 p {
  line-height: 26px;
}

.meritPageNavi {
  margin: 0 auto 80px;
}

.meritPageNavi > picture {
  display: block;
  margin: 0 auto 10px;
  max-width: 176px;
}

.meritPageNavi > picture img {
  display: block;
  width: 100%;
}

.meritPageNavi p {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 40px;
  text-align: center;
}

.meritPageNavi nav {
  max-width: 640px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 0 auto 80px;
}

.meritPageNavi nav a {
  margin: 5px;
}

@media screen and (max-width: 800px) {
  .meritPageNavi nav a {
    max-width: 200px;
    margin-bottom: 20px;
  }
}

.meritPageNavi nav a picture img {
  width: 100%;
}

.meritSection {
  max-width: 600px;
  margin: 0 auto 80px;
}

.meritSection:nth-of-type(1) {
  margin-bottom: 35px;
}

.meritSection:nth-of-type(2) hr + p {
  margin-bottom: 65px;
}

.meritSection:nth-of-type(2) h3 {
  margin-bottom: 20px;
}

.meritSection:nth-of-type(2) p {
  margin-bottom: 30px;
}

.meritSection:nth-of-type(2) img {
  margin-bottom: 25px;
}

.meritSection:nth-of-type(3) h3 {
  margin-bottom: 20px;
}

.meritSection:nth-of-type(3) img {
  margin-bottom: 20px;
}

.meritSection:nth-of-type(4) h3 {
  margin-bottom: 20px;
}

.meritSection:nth-of-type(4) h3 + p {
  margin-bottom: 30px;
}

.meritSection:nth-of-type(4) img {
  margin-bottom: 40px;
}

.meritSection:nth-of-type(4) img + p {
  margin-top: -20px;
}

.meritSection:nth-of-type(5) h3 {
  margin-bottom: 20px;
}

.meritSection:nth-of-type(5) img {
  margin-bottom: 20px;
}

.meritSection:nth-of-type(6) {
  max-width: 815px;
  margin-bottom: 100px;
}

.meritSection:nth-of-type(6) p {
  margin-bottom: 40px;
  text-align: center;
}

.meritSection h2 {
  margin-bottom: 25px;
  font-size: 40px;
  color: #001c58;
  text-align: center;
}

.meritSection hr {
  width: 65px;
  border-width: 2px;
  border: 1px solid #000000;
  margin: 0 auto 30px;
}

.meritSection > p {
  margin-bottom: 40px;
  line-height: 26px;
}

.meritSection__caption {
  margin-bottom: 40px;
  line-height: 26px;
}

@media screen and (max-width: 800px) {
  .meritSection__caption {
    text-align: center;
  }
}

.meritSection__caption br {
  display: none;
}

@media screen and (max-width: 800px) {
  .meritSection__caption br {
    display: block;
  }
}

.meritSection h3 {
  margin: 0 auto 30px;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}

.meritSection h3 .br {
  display: none;
}

@media screen and (max-width: 800px) {
  .meritSection h3 .br {
    display: block;
  }
}

.meritSection ol {
  margin-bottom: 35px;
  line-height: 26px;
  list-style: none;
}

.meritSection ol li {
  padding-left: 0.5em;
  display: flex;
}

.meritSection ol li span {
  display: block;
  padding-right: 3px;
}

.meritSection ol li p {
  display: inline;
}

.meritSection ul {
  width: 250px;
  margin: 0 auto 30px;
}

.meritSection ul li {
  margin-bottom: 10px;
}

.meritSection picture {
  display: block;
  margin: 0 auto 60px;
}

.meritSection picture img {
  display: block;
  width: 100%;
  margin: 0 auto 60px;
}

.meritSection .ownerComment {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 auto;
}

.meritSection .ownerComment div {
  max-width: 400px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin: 0 auto 40px;
}

.meritSection .ownerComment div:last-of-type {
  margin-bottom: 0;
}

.meritSection .ownerComment div picture {
  margin: 0 auto;
}

.meritSection .ownerComment div picture img {
  width: 100%;
  margin: 0;
}

.meritSection .ownerComment div span {
  display: block;
  margin-bottom: 5px;
}

/* ****************************** */
/* [006] サイドコンテンツ */
/* ****************************** */
.sideCont {
  background-color: #9f9;
}

/* ****************************** */
/* [007] グローバルフッター */
/* ****************************** */
.glbFooter {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #040404;
  width: 100%;
  padding: 5%;
  color: #ffffff;
}

.footerNavi {
  width: 90%;
  max-width: 700px;
  margin: 0 auto;
}

@media screen and (max-width: 800px) {
  .footerNavi {
    margin-bottom: 40px;
    max-width: unset;
    width: 90%;
  }
}

.footerNavi ul {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
}

.footerNavi ul li {
  margin-bottom: 40px;
}

@media screen and (max-width: 800px) {
  .footerNavi ul li {
    margin-bottom: 5px;
  }
}

.footerNavi ul li + li::before {
  content: "|";
  display: inline-block;
  margin: 0 5px;
}

@media screen and (max-width: 800px) {
  .footerNavi ul li + li::before {
    margin: 0 .5px;
  }
}

@media screen and (max-width: 800px) {
  .footerNavi ul li:nth-of-type(5)::before {
    display: none;
  }
}

.footerNavi ul li a {
  display: inline-block;
  padding: 3px 5px;
  border-radius: 15px;
  border-radius: 5px;
  color: #ffffff;
  transition: .3s;
}

.footerNavi ul li a:hover {
  background-color: #ffffff;
  color: #000000;
  transition: .3s;
}

.footerNavi ul li a:active {
  transition: .3s;
}

.companyInfo {
  display: flex;
  justify-content: space-between;
  justify-content: flex-start;
  align-items: flex-start;
  width: 800px;
  margin: 0 auto;
}

.companyInfo__logo {
  height: 120px;
  margin-right: 40px;
}

@media screen and (max-width: 800px) {
  .companyInfo__logo {
    height: unset;
    margin-right: 0;
    margin-bottom: 30px;
  }
}

.companyInfo__data {
  display: block;
  font-size: 12px;
}

.companyInfo__data p {
  font-size: 18px;
  font-weight: 600;
}

@media screen and (max-width: 800px) {
  .companyInfo__data p {
    text-align: center;
  }
}

.companyInfo__data address {
  width: 100%;
  display: block;
}

@media screen and (max-width: 800px) {
  .companyInfo__data address {
    text-align: center;
  }
}

.companyInfo__data address .tel {
  display: inline-block;
  position: relative;
  height: 10px;
  margin-right: 3px;
}

.companyInfo__data address .copyright {
  display: block;
  margin-top: 40px;
  font-size: 12px;
}

@media screen and (max-width: 800px) {
  .companyInfo__data address .copyright {
    margin-top: 20px;
  }
}

.companyInfo__data address .br {
  display: inline-block;
}

@media screen and (max-width: 800px) {
  .companyInfo__data address .br {
    display: block;
  }
}

.companyInfo__data address .br::after {
  content: "　";
  display: block;
}

@media screen and (max-width: 800px) {
  .companyInfo__data address .br::after {
    content: "";
  }
}

.companyInfo__data address .brSl {
  display: inline-block;
}

@media screen and (max-width: 800px) {
  .companyInfo__data address .brSl {
    display: block;
  }
}

.companyInfo__data address .brSl::after {
  content: " ／ ";
  display: block;
  margin: 0 3px;
}

@media screen and (max-width: 800px) {
  .companyInfo__data address .brSl::after {
    content: "";
  }
}

@media screen and (max-width: 800px) {
  .companyInfo {
    flex-direction: column;
    align-items: center;
    width: 300px;
  }
}

/* ****************************** */
/* [008] その他の分割パーツ（WPプラグインなど） */
/* ****************************** */
.wp-pagenavi {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px auto;
}

.wp-pagenavi > * {
  margin: 0 !important;
  margin-right: 20px !important;
  transition: .3s;
}

.wp-pagenavi > *:last-child {
  margin-right: 0;
}

.wp-pagenavi .current {
  background-color: #001c58;
}

.wp-pagenavi .page.larger,
.wp-pagenavi .page.smaller {
  background-color: transparent;
}

.wp-pagenavi .page.larger:hover,
.wp-pagenavi .page.smaller:hover {
  transition: .3s;
  background-color: rgba(0, 28, 88, 0.5);
}

.wp-pagenavi .current,
.wp-pagenavi .page.larger,
.wp-pagenavi .page.smaller {
  display: block;
  width: 30px;
  height: 30px;
  border: 1px solid #001c58;
  border-radius: 50%;
  padding: 0;
}

.wp-pagenavi .current span,
.wp-pagenavi .page.larger span,
.wp-pagenavi .page.smaller span {
  display: block;
  border: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  margin: 0;
}

/*
.wp-pagenavi .page.larger {
  background-color: red;
}
*/
/*
.wp-pagenavi .page.larger span {
  width: 200px;
}
*/
.omission {
  display: inline-block;
  margin-left: 3px;
  font-size: 50%;
}

/*投稿タイトルの省略記号*/
.ajax-loader {
  display: none !important;
}

.wpcf7-response-output.wpcf7-display-none.wpcf7-validation-errors,
.wpcf7-response-output.wpcf7-display-none.wpcf7-mail-sent-ok,
.wpcf7-response-output.wpcf7-mail-sent-ok,
.wpcf7-mail-sent-ok {
  background-color: #001c58;
  border: 3px solid #001c58;
  padding: 10px;
  text-align: center;
  font-size: 16px;
  color: #ffffff;
}

span.wpcf7-list-item {
  margin: 0 !important;
}

/* ****************************** */
/* [mq001] メディアクエリ */
/* ****************************** */
