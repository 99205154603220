@charset "UTF-8";
// ******************************
// [005] メインコンテンツ（トップページ）
// ******************************
.mainCont {
  background-color: $c-gray;
//  overflow: hidden;
  max-width: 1000px;
  margin: 0 auto;
}

.container--pc {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto 20px;
  .seminarPage & {
    max-width: 100%;
    margin: 0;
  }
  @include mq() {
    max-width: 640px;
    padding: 0 20px;
    .businessPage &,
    .contactPage &,
    .recruitmentPage &,
    .seminarPage & {
      width: 100%;
      max-width: 100%;
      padding: 0;
    }
    .glbFooter & {
//      width: 100%;
      padding: 0;
    }
  }
} /* PCサイズ用のコンテナ */

.newsHeadline {
  width: 100%;
  margin: 0 auto;
  &__title {
    padding: 50px 0;
    text-align: center;
    font-size: 40px;
    color: $c-blue;
  }
  &__link {
    display: block;
    width: 150px;
    text-decoration: underline;
    margin: 0 auto;
    font-size: 20px;
    color: $c-black;
    text-align: center;
    &::after {
      content: ">";
      display: inline-block;
      margin: 0 0 0 10px;
//      border-bottom: 0 solid $c-red !important;
    }
  }
}
.newsList {
  margin-bottom: 40px;
  &Item {
    width: 100%;
    background-color: $c-white;
    margin-bottom: 20px;
    transition: .3s;
    @include hoverShadow();
    & a {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: $c-black;
      & time {
        padding: .3em 10px;
        margin-right: 10px;
      }
      & span {
        display: block;
        padding: .2em;
        word-break: break-word;
      }
    }
  }
}

.contentsBox {
//            background-color: #4fb763;
  position: relative;
  margin: 0 auto;
  width: 1000px;
  height: 520px;
  margin-bottom: 60px;
  font-size: 0;
  @include mq() {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
//    max-height: 800px;
//    min-height: 600px;
    margin-bottom: 100px;
  }
  &__title {
    ._merit & {
      @include mq() {
        height: 100px;
      }
    }
    height: 45px;
    & img {
      display: block;
      height: 100%;
    }
    & span {
      display: none;
    }
    ._right & {
      @include positionTL(0, 0, 200);
      @include mq() {
//        @include positionTL(0, 40px, 200);
        @include positionTL(10px, 20px, 200);
      }
    }
    ._left & {
      @include positionTR(0, 0, 200);
      @include mq() {
//        @include positionTR(0, 40px, 200);
        @include positionTR(10px, 20px, 200);
      }
    }
  }
  &__text {
    width: 240px;
    font-size: 16px;
    ._right & {
      @include positionTL(100px, 0, 200);
      @include mq() {
        order: 2;
        position: static;
        width: 90%;
        margin: 20px auto;
      }
    }
    ._left & {
      @include positionTR(100px, 0, 200);
      @include mq() {
        order: 2;
        position: static;
        width: 90%;
        margin: 20px auto;
      }
    }
    & span {
      display: block;
    }
  }
  &__photo {
    display: block;
    transition: .3s;
    @include mq() {
      max-height: 250px;
    }
    & picture {
      display: block;
      width: 100%;
      height: 100%;
      & img {
        width: 100%;
        height: 100%;
        @include mq() {
          object-fit: cover;
        }
      }
    }
    ._right & {
      @include positionBR(0, 0, 200);
      @include mq() {
        order: 1;
        position: static;
        width: 100%;
        height: 350px;
        margin-top: 115px;
      }
    }
    ._left & {
      @include positionBL(0, 0, 200);
      @include mq() {
        order: 1;
        position: static;
        width: 100%;
        height: 350px;
        margin-top: 115px;
      }
    }
    ._merit & {
      @include mq() {
        margin-top: 125px;
      }
    }
    &:hover {
      transform: scale(1.08);
      transition: .3s;
      @include mq() {
        transform: unset;
      }
    }
  }
  &__linkBar {
    display: block;
    @include hoverOpacity();
    ._right & {
      @include positionBL(60px, 0, 100);
      @include mq() {
        order: 3;
        position: static;
//        @include positionBL(0, 0px, 200);
        width: 100%;
        margin: 0 auto;
        & img {
          display: block;
          width: 90%;
          height: unset;
          margin: 0 auto;
        }
      }
    }
    ._left & {
      @include positionBR(60px, 0, 100);
      @include mq() {
        order: 3;
        position: static;
//        @include positionBL(0, 0px, 200);
        width: 100%;
        margin: 0 auto;
        & img {
          display: block;
          width: 90%;
          height: unset;
          margin: 0 auto;
        }
      }
    }
  }
  &__grayBar {
    display: block;
    height: calc(100% - 25px);
    @include mq() {
      height: calc(100% - 25px);
    }
    ._right & {
      @include positionTR(50px, 350px, 100);
      @include mq() {
        @include positionTR(25px, 20px, 0);
        & img {
          height: 100%;
        }
      }
    }
    ._left & {
      @include positionTL(50px, 350px, 100);
      @include mq() {
        @include positionTL(25px, 20px, 0);
        & img {
          height: 100%;
        }
      }
    }
    & img {
      display: block;
//      height: 100%;
    }
  }
}

.linkBtnBox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
  & a {
    display: block;
    font-size: 0;
    margin: 0 auto;
  }
  &._tel {
    margin-top: 40px;
    & .linkBtnBox__inner {
      & h6 {
        display: block;
//        height: 50px;
        height: 35px;
        margin: 0 auto 15px;
        & span {
          display: none;
        }
        & picture {
          display: block;
          height: 100%;
          & img {
            display: block;
//            width: 100%;
            height: 100%;
            margin: 0 auto;
          }
        }
      }
      & > span {
        display: block;
        margin-bottom: 20px;
        font-size: 24px;
        text-align: center;
        & > img {
          display: block;
          margin: 0 auto;
          @include mq() {
            height: 50px;
          }
        }
        & > span {
          display: none;
        }
      }
      & p {
        margin-bottom: 20px;
        text-align: center;
        @include mq() {
          padding: 0 20px;
          text-align: left;
        }
      }
      & a:hover {
        transition: .3s;
        opacity: 0.5;
      }
    }
  }
  &._recruitment {
    background-color: $c-black;
    width: 100%;
    color: $c-white;
    margin: unset;
    margin-top: 40px;
    & a:hover {
      background-color: lighten($c-black, 30%);
    }
  }
  &._contact {
    background-color: transparent;
    width: 100%;
    margin-top: 0;
    & a {
//      display: block;
//      margin: 0 auto;
      & img:hover {
        transition: .3s;
        opacity: 0.3;
      }
    }
    @include mq() {
      height: 252px;
    }
  }
  @include mq() {
    & a {
      width: 90%;
      & picture {
        display: block;
        width: 100%;
        margin: 0 auto;
        & img {
          display: block;
          width: 100%;
        }
      }
    }
  }
}

// ******************************
// [006] メインコンテンツ（NEWSページ）
// ******************************
.mainVisualB {
  position: relative;
  width: 100%;
  height: 300px;
  margin-bottom: 50px;
  @include dotOverlay();
  @include mq() {
    height: unset;
    margin-top: 70px;
  }
  &__title {
    display: none;
  }
  &__visualImg {
    display: block;
    & img {
      display: block;
      object-fit: cover;
      width: 100%;
      height: 300px;
      @include mq() {
        height: 100%;
      }
    }
  }
  &__titleImg {
    display: block;
    @include positionTL( calc(50% - 35px), calc(50% - 257.469px), 300 );
    height: 70px;
    & img {
      height: 100%;
    }
    @include mq() {
      @include positionTL( calc(50% - 30px), calc(50% - 202.297px), 300 );
      height: 55px;
    }
  }
  &__rightNowLink {
    display: block;
    background: url(../res/img/common/btn-right-now.png) center center/360px no-repeat;
    width: 360px;
    height: 70px;
    position: absolute;
    bottom: 20px;
    left: calc(50% - 180px);
    z-index: 500;
    transition: .3s;
    @include mq() {
      bottom: 40px;
      width: 200px;
      background-size: 200px;
      left: calc(50% - 100px);
      bottom: 20px;
    }
    &:hover {
      transform: scale(1.05);
      transition: .3s;
    }
    &:active {
      transition: .3s;
      transform: translateY(5px);
    }
  }
}
.mainVisualC {
  position: relative;
  max-width: 1000px;
  height: 489px;
  margin: 50px auto 40px;
  @include mq() {
    width: 100%;
    height: 300px;
    height: unset;
    margin-top: 70px;
  }
  &__title {
    display: none;
  }
  & .titleImgWrapperr {
    @include positionTL( 0, 0, 300);
    background-color: transparent;
    width: 100%;
    max-width: 1000px;
    height: 100%;
    @include mq() {
      position: static;
      max-width: 640px;
      padding: 0 40px;
      margin: 0 auto;
      background-color: #f01;
    }
    & .mainVisualC__titleImg {
      height: 150px;
      @include positionTL( 65px, -1440px, 300 );
      @include mq() {
        @include positionTL( 30px, -460px, 300 );
        height: 80px;
      }
      & img {
        display: block;
        height: 100%;
      }
      .groupPage & {
        @include positionTL( 65px, -1380px, 300 );
        @include mq() {
          @include positionTL( 30px, -460px, 300 );
          height: 80px;
        }
      }
    }
  }
  &__mainImg {
    @include positionTL( 0, calc(50% - 400px), 200 );
    @include mq() {
      position: static;
      background-color: #ff0;
      margin: 0 auto;
      & img {
        object-fit: cover;
        width: 100%;
        height: 440px;
        @include mq() {
          height: 100%;
        }
//        display: none;
      }
    }
  }
  &__square {
    @include positionTR( calc(244.5px - 100px), -50px, 100 );
    @include mq() {
      @include positionBR( 30px, 0, 200 );
      & img {
        max-height: 56px;
      }
    }
  }
  &__gray {
    @include positionBL( 40px, -50px, 100 );
    @include mq() {
      position: static;
      @include positionBR( 30px, 11.19px, 100 );
      & img {
        max-height: 56px;
      }
    }
  }
}
.mainVisualD {
  position: relative;
  width: 600px;
  height: 600px;
  margin: 50px auto 50px;
  overflow: hidden;
  @include mq() {
    margin: 0 0 50px;
    width: 100%;
    height: unset;
  }
  &__visualImg {
    display: block;
//    @include positionTL( calc(50% - 300px), calc(50% - 300px), 100 );
//    width: 100%;
    height: 100%;
    @include mq() {
      position: static;
      margin-top: 70px;
      width: 100%;
    }
    & img {
//      width: 100%;
      height: 100%;
      margin: 0 auto;
      @include mq() {
        height: 50vh;
        min-height: 300px;
      }
    }
  }
}

.wpr {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto 40px;
  @include mq() {
    max-width: 640px;
    padding: 0 40px;
    .businessPage &,
    .contactPage &,
    .recruitmentPage & {
      width: 100%;
      max-width: 100%;
      padding: 0;
    }
  }
}

.newsBox {
  padding: 20px;
  margin-bottom: 100px;
  & .newsItem {
    padding: 40px 0;
    border-bottom: 1px solid $c-gray_3;
    &:first-of-type {
      border-top: 1px solid $c-gray_3;
    }
    &__date {
      display: block;
      margin-bottom: 20px;
      color: #666666;
    }
    &__title {
      display: inline-block;
      color: $c-black;
      text-decoration: underline;
      transition: .3s;
      &:hover {
        color: $c-red;
        transition: .3s;
      }
    }
  }
}

.postContents {
  max-width: 800px;
  margin: 0 auto 60px;
  @include mq() {
    max-width: 560px;
  }
  &__title {
    font-size: 24px;
    color: $c-blue;
    margin-bottom: 30px;
  } /* 投稿記事のタイトル */
  &__content {
//    background-color: #ff0;
    line-height: 2;
  } /* 投稿記事のコンテンツ */
} /* 投稿記事 */

// ******************************
// [007] メインコンテンツ（NEWS投稿ページ）
// ******************************
.newsArticle {
  max-width: 1000px;
  padding: 100px;
  border: 1px solid $c-gray_3;
  margin: 0 auto 100px;
  @include mq() {
    padding: 50px 35px;
  }
  &__date {
    display: block;
    margin-bottom: 40px;
    color: $c-black_3;
  }
  &__title {
    font-size: 24px;
    margin-bottom: 40px;
  }
  &__content {
    color: $c-black_3;
    line-height: 1.7;
    & p {
      margin-bottom: 35px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

// ******************************
// [007] メインコンテンツ（プライバシーポリシーページ）
// ******************************
.privacypolicyList {
  max-width: 800px;
  margin: 0 auto 100px;
  & dt {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  & dd {
    line-height: 28px;
    margin-bottom: 40px;
    & ul {
      list-style: disc;
      margin-left: 1.5em;
    }
  }
}

// ******************************
// [007] メインコンテンツ（不動産販売／不動産開発／コンサルティングページ）
// ******************************
.introduction {
  width: 100%;
  margin: 0 auto 40px;
  text-align: center;
  @include mq() {
    max-width: 560px;
  }
  & h2 {
    font-size: 24px;
    color: $c-blue;
    margin-bottom: 30px;
    & + span {
      display: block;
      font-size: 18px;
      font-weight: 600;
      text-align: center;
      margin: 0 auto 25px;
      @include mq() {
        text-align: left;
      }
    }
  }
  &__subHead {
  }
  & p {
    font-size: 18px;
    line-height: 30px;
    font-weight: 600;
    margin-bottom: 18px;
    @include mq() {
      line-height: 1.4;
      text-align: left;
      .business_5Page & {
        text-align: center;
      }
      & br {
        display: none;
      }
    }
  }
  & span {
    display: block;
    max-width: 600px;
    margin: 0 auto;
    font-size: 18px;
    line-height: 30px;
    text-align: left;
  }
  .recruitmentPage & {
    margin-bottom: 60px;
    & h2 {
      @include mq() {
        margin: 0 25px;
      }
    }
    & span {
      @include mq() {
        margin: 0 25px;
      }
    }
    & p {
      max-width: 600px;
      font-size: 14px;
      font-weight: 100;
      line-height: 26px;
      margin: 0 auto;
      @include mq() {
        margin: 0 25px;
        text-align: left;
      }
    }
  }
}
.bizPhoto {
  width: 100%;
  margin: 0 auto;
  .business_1Page & {
    max-width: 600px;
    margin-bottom: 40px;
    @include mq() {
      & img {
        max-width: 100%;
      }
    }
  }
  .business_2Page & {
    max-width: 735px;
    margin-bottom: 100px;
    @include mq() {
      & img {
        max-width: 100%;
      }
    }
  }
  .business_4Page & {
    position: relative;
    width: 90%;
    margin: 0 auto 100px;
    & picture {
      display: block;
      width: 100%;
      margin: 0 auto 20px;
      & img {
        display: block;
        width: 100%;
      }
    }
    @include mq() {
      width: 100%;
    }
  }
  .business_5Page & {
    max-width: 720px;
    margin-bottom: 80px;
    @include mq() {
      & img {
        max-width: 100%;
      }
    }
  }
  & picture {
    display: block;
    margin-bottom: 10px;
  }
  & p {
    line-height: 24px;
    .business_5Page & {
      text-align: center;
      margin-bottom: 30px;
      @include mq() {
        text-align: left;
      }
    }
  }
  &__title {
    text-align: center;
    color: $c-blue;
    font-size: 24px;
    margin-bottom: 20px;
  }
  &__commentaryBox {
//    width: 530px;
//    @include positionTL(290px, calc(50% - 265px), 100);
    background-color: rgba(#001c58, .9);
    width: 85%;
//    max-width: 520px;
    padding: 30px 40px;
    margin: 0 auto;
    color: $c-white;
    transform: translateY(-50px);
    @include mq() {
      width: 90%;
    }
    & h3 {
      margin-bottom: 20px;
      font-size: 24px;
      text-align: center;
    }
    & p {
      line-height: 26px;
    }
  }
}

// ******************************
// [007] メインコンテンツ（資産運用ページ）
// ******************************
.business3List {
  background-color: #f00;
  background-color: $c-white;
  width: 100%;
  counter-reset: num;
  & p {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $c-black;
    height: 50px;
    color: $c-white;
    font-size: 24px;
    text-align: center;
  }
  & ol {
    font-size: 18px;
    max-width: 1000px;
    margin-bottom: 100px;
    & li {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      border-bottom: 1px solid #dfdfdf;
      & span {
        display: block;
        width: 260px;
        &::before {
          counter-increment: num;
          content: counter(num) ".";
          display: inline-block;
          padding-right: 2px;
        }
      }
    }
  }
}

// ******************************
// [007] メインコンテンツ（事業案内ページ）
// ******************************
.businessBox {
  display: block;
  position: relative;
  background-color: $c-blue;
  max-width: 800px;
  height: 200px;
  margin: 0 auto 30px;
  @include mq() {
    width: 10%;
    margin-bottom: 60px;
    width: 100%;
    height: unset;
  }
  &:nth-of-type(5) {
    margin-bottom: 100px;
  }
  &__visual {
    ._left &,
    ._right & {
      @include mq() {
        display: block;
        position: static;
        width: 100%;
        & img {
          display: block;
          width: 100%;
          height: 280px;
          object-fit: cover;
        }
      }
    }
    ._left & {
      @include positionTL(0, 0, 100);
    }
    ._right & {
      @include positionTR(0, 0, 100);
    }
  }
  &__inner {
    width: 300px;
    padding-top: 20px;
    padding-bottom: 20px;
    color: $c-white;
    & h3 {
      width: 210px;
      margin-bottom: 20px;
      font-size: 24px;
      text-align: center;
      @include mq() {
        width: unset;
      }
    }
    & p {
      width: 210px;
      line-height: 26px;
      @include mq() {
        width: unset;
        text-align: left;
      }
    }
    ._left &,
    ._right & {
      @include mq() {
        position: static;
        width: 100%;
        padding: 40px;
        margin: 0 auto;
        text-align: center;
      }
    }
    ._left & {
      @include positionTR(0, 0, 100);
      padding-right: 80px;
      padding-left: 10px;
    }
    ._right & {
      @include positionTL(0, 0, 100);
      padding-left: 80px;
      padding-right: 10px;
    }
  }
  &__btn {
    ._left & {
      @include positionTR( calc(50% - 15px), 20px, 200);
      @include mq() {
        @include positionTR( 0, 0, 100 );
      }
    }
    ._right & {
      @include positionTL( calc(50% - 15px), 20px, 200);
      @include mq() {
        @include positionTR( 0, 0, 100 );
      }
    }
  }
  
  &:hover {
    @include hoverOpacity();
  }
} /* 各ビジネス個別ページへのリンクボックス */

// ******************************
// [007] メインコンテンツ（サンクスページ）
// ******************************
.messageBox {
  max-width: 800px;
  height: 200px;
  background-color: #a0a0a0;
  color: $c-white;
  font-size: 35px;
  line-height: 50px;
  font-weight: 600;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 30px;
  @include mq() {
//    width: 560px;
  }
}
.annotationBox {
  max-width: 800px;
  font-size: 16px;
  line-height: 28px;
  margin: 0 auto 30px;
}

// ******************************
// [007] メインコンテンツ（入居率情報／販売実績ページ）
// ******************************
.cardContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1000px;
  &._immigration {
    margin-bottom: 40px;
    & .card {
      @include mq() {
        width: 100%;
      }
      & .card__thum {
        width: 100%;
        & img {
          display: block;
          width: 100%;
        }
      }
      &__inner {
        padding: 20px;
        & h3 {
          color: $c-blue;
        }
      }
      & .imgBtn {
        margin-bottom: 0;
      }
    }
  }
  &._sales {
    margin-bottom: 100px;
    & .card {
      padding: 20px 20px 30px;
      @include mq() {
        width: 100%;
        padding: 30px;
      }
      &__thum {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 260px;
        height: 260px;
        overflow: hidden;
        position: relative;
        margin: 0 auto 20px;
        @include mq() {
          width: 200px;
          height: 200px;
        }
        & img {
          width: 100%;
        }
      }
      &__inner {
        & h3 {
          text-align: center;
        }
        & picture {
          & img,
          & source {
            margin: 0 auto;
          }
        }
      }
    }
    & .imgBtn {
//      margin-bottom: 0;
    }
  }
} /* カード格納用コンテナ */
.card {
  background-color: $c-white;
  width: 300px;
  box-shadow: 0 3px 3px lighten($c-black, 60%);
  margin-bottom: 30px;
  transition: .3s;
  &:hover {
    box-shadow: none;
    transition: .3s;
    transform: translateY(-3px);
  }
  &__thum {
    & img {
      display: block;
    }
  }
  &__inner {
    width: 100%;
    & h3 {
      height: 50px;
      margin-bottom: 20px;
      font-size: 18px;
      line-height: 28px;
    }
    & p {
      margin-bottom: 20px;
      line-height: 24px;
//      height: 45px;
    }
    & a img {
      display: block;
      margin: 20px auto;
      margin-bottom: 27px;
      transition: .3s;
      @include hoverOpacity();
      &:hover {
        transform: translateX(3px);
      }
    }
  }
} /* 投稿記事用カード */
.postPager {
  width: 100%;
  margin-bottom: 100px;
  display: flex;
  justify-content: center;
  & a {
    display: block;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    border: 1px solid $c-blue;
    margin-right: 12px;
    transition: .3s;
    &:last-of-type {
      margin-right: 0;
    }
    @include mq() {
      width: 24px;
      height: 24px;
    }
  }
  &__this {
    background-color: $c-blue;
  }
  &__more {
    background-color: transparent;
    &:hover {
      transition: .3s;
      background-color: $c-blue;
    }
  }
} /* 投稿ループ用ページャ */

// ******************************
// [007] メインコンテンツ（販売実績ページ）
// ******************************
.goMerit {
  background-color: #cfc4b3;
  width: 800px;
  padding: 30px 40px;
  margin: 0 auto 80px;
  text-align: center;
  @include mq() {
    width: 100%;
    padding: 15px 20px;
  }
  & p {
    margin: 0 auto 18px;
    font-size: 18px;
    line-height: 30px;
    & .br {
      display: none;
      @include mq() {
        display: block;
      }
    }
  }
  & a {
    display: block;
    max-width: 600px;
    margin: 0 auto;
    @include hoverOpacity(.7);
    @include mq() {
      max-width: 300px;
    }
    & img {
      width: 100%;
      display: block;
    }
  }
} /* メリットへのリンクボックス */

// ******************************
// [007] メインコンテンツ（販売実績の投稿ページ）
// ******************************
.salesTitle {
  font-size: 40px;
  color: $c-blue;
  max-width: 800px;
  text-align: center;
  margin: 0 auto 30px;
  @include mq() {
    text-align: left;
  }
}
.salesAddress {
  max-width: 560px;
  margin: 0 auto 30px;
  font-weight: 600;
  line-height: 26px;
}
.salesHr {
  max-width: 1000px;
  &._s {
    border: 1px solid $c-black;
    @include mq() {
      margin-bottom: 40px;
    }
  }
  &._m {
    border: 3px solid $c-black;
    margin-bottom: 60px;
  }
}
.salesText {
  max-width: 560px;
  margin: 0 auto 30px;
  line-height: 26px;
  @include mq() {
    margin-bottom: 50px;
  }
}
.salesData {
  display: flex;
  justify-content: space-between;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 700px;
//  height: 1000px;
  margin: 0 auto 40px;
  @include mq() {
//    width: 560px;
    display: block;
    height: 300px;
//    flex-direction: column;
    margin-bottom: 80px;
  }
  & dl {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #cfc4b3;
    width: 220px;
    height: 90px;
    text-align: center;
    & + dl {
      margin-left: 20px;
      @include mq() {
//        margin-left: 0;
        margin: 0 auto 20px;
      }
    }
    @include mq() {
      width: 400px;
//      margin-top: 20px;
      margin: 0 auto 20px;
//      margin-left: 20px;
    }
    & dt {
      width: 120px;
//      height: 24px;
      font-size: 16px;
      margin-bottom: 10px;
      color: #cfc4b3;
      background-color: $c-white;
      border-radius: 3px;
      @include mq() {
        width: 180px;
//        font-size: 32px;
      }
    }
    & dd {
      font-size: 24px;
      color: $c-white;
    }
    &:nth-of-type(1) {
      & dd::before {
        content: "¥";
        display: inline-block;
        padding-right: 5px;
      }
    }
    &:nth-of-type(2) {
      & dd span:nth-of-type(2) {
        & sup {
          font-size: 50%;
          vertical-align: super;
        }
      }
    }
  }
}
.salesGallery {
  display: flex;
//  justify-content: space-between;
  justify-content: space-around;
//  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  max-width: 700px;
  margin: 0 auto calc(60px - 20px);
  font-size: 0;
  @include mq() {
    max-width: 560px;
    margin-bottom: 100px;
  }
  & a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 160px;
    margin-bottom: 20px;
    margin-right: 15px;
    overflow: hidden;
    @include mq() {
      max-width: 250px;
      max-height: 250px;
      width: 125px;
      height: 125px;
      margin-right: 0;
    }
    &:hover {
      opacity: .8;
    }
    & img {
      display: block;
      width: 320px;
    }
  }
} /* 投稿に紐付いた複数画像ギャラリー */

// ******************************
// [007] メインコンテンツ（会社案内ページ／グループ企業概要ページ）
// ******************************
.details {
  margin-bottom: 90px;
  &__title {
    margin-bottom: 30px;
    font-size: 24px;
    text-align: center;
  }
  &__list {
    max-width: 600px;
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
    margin: 0 auto;
    & dt {
      background-color: #c9c9c9;
      width: 110px;
      padding: .2em .5em;
      &:nth-of-type(2n) {
        background-color: $c-gray;
      }
    }
    & dd {
      background-color: #c9c9c9;
      width: calc(100% - 110px);
      padding: .2em;
      &::before {
        content: "：";
        display: inline-block;
        text-indent: -1em;
      }
      &:nth-of-type(2n) {
        background-color: $c-gray;
      }
    }
  }
  &__hr {
    width: 60px;
    border: 1px solid $c-black;
    margin: 0 auto 30px;
  }
} /* （物件などの）詳細 */
.pointBox {
  max-width: 800px;
  margin: 0 auto 80px;
  .groupPage &:nth-of-type(1) {
    & h2 {
      & br {
        display: none;
        @include mq() {
          display: block;
        }
      }
    }
    & p {
      max-width: 600px;
      margin: 0 auto;
    }
  }
  &:last-of-type {
    margin-bottom: 100px;
  }
  &__title {
    margin-bottom: 30px;
    font-size: 40px;
    text-align: center;
    .groupPage & {
      color: $c-blue;
      font-size: 24px;
    }
  }
  &__hr {
    width: 60px;
    border: 1px solid $c-black;
    margin: 0 auto 30px;
  }
  &__img {
    display: block;
    margin: 0 auto;
    & img {
      width: 100%;
    }
  }
  &__subPointBox {
    margin-bottom: 60px;
    & h3 {
      display: block;
      margin: 0 auto 10px;
      font-size: 18px;
      text-align: center;
      color: #535353;
    }
    & span {
      display: block;
      width: 1px;
      height: 30px;
      background-color: #535353;
      border: 1px solid #535353;
      margin: 0 auto 10px;
    }
    & picture {
      display: block;
      max-width: 400px;
      margin: 0 auto 18px;
      & img {
        display: block;
        width: 100%;
      }
    }
    & p {
      max-width: 400px;
      line-height: 26px;
      margin: 0 auto 20px;
      @include mq() {
        line-height: 1.5;
      }
    }
    & div {
      display: flex;
      justify-content: space-between;
      max-width: 400px;
      margin: 0 auto;
      & picture {
        display: block;
        margin: 0;
        @include mq() {
          &:last-of-type {
            margin-left: 20px;
          }
        }
        & img {
          display: block;
        }
      }
    }
  }
  &__comment {
    background-color: $c-white;
    max-width: 800px;
    padding: 60px;
    border: 1px solid $c-black;
    margin: 0 auto;
    & p {
      font-size: 16px;
      line-height: 28px;
      margin-bottom: 40px;
      &:last-of-type {
        text-align: right;
        font-weight: 600;
        margin-bottom: 0;
      }
      @include mq() {
        line-height: 1.4;
      }
    }
    @include mq() {
      padding: 15px;
    }
    & dl {
      font-size: 16px;
      & dt {
        margin-bottom: 10px;
        font-weight: 800;
      }
      & dd {
        margin-bottom: 40px;
        line-height: 28px;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
  &__list {
    max-width: 600px;
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
    margin: 0 auto;
    & dt {
      background-color: #c9c9c9;
      width: 110px;
      padding: .2em .5em;
      &:nth-of-type(2n) {
        background-color: $c-gray;
      }
    }
    & dd {
      background-color: #c9c9c9;
      width: calc(100% - 110px);
      padding: .2em;
      &::before {
        content: "：";
        display: inline-block;
        text-indent: -1em;
      }
      &:nth-of-type(2n) {
        background-color: $c-gray;
      }
    }
  }
  &__address {
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
    @include mq() {
      flex-direction: column;
    }
    & picture {
      height: 255px;
      margin-right: 20px;
      @include mq() {
        order: 2;
        width: 100%;
        height: unset;
      }
      & img {
        display: block;
        width: 100%;
      }
    }
    & address {
      display: block;
      width: 70%;
      @include mq() {
        order: 1;
        width: 100%;
      }
      & p {
        display: block;
        line-height: 26px;
        margin-bottom: 30px;
        @include mq() {
//          margin-bottom: 10px;
        }
        &:first-of-type {
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 0;
        }
        &:last-of-type {
//          margin-bottom: 0;
        }
        & .br {
          display: none;
          @include mq() {
            display: block;
          }
        }
      }
    }
  }
} /* 会社の活動紹介ボックス */

// ******************************
// [007] メインコンテンツ（お問い合わせ／不動産情報募集／セミナーページ）
// ******************************
.recruitmentIntroduction {
  max-width: 600px;
  margin: 0 auto 80px;
  @include mq() {
    max-width: 560px;
  }
  & h3 {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 20px;
  }
  & hr {
    width: 60px;
    border: 1px solid $c-black;
    margin: 0 auto 14px;
  }
  & p,
  & address {
    max-width: 600px;
    margin: 0 auto 40px;
    text-align: center;
    line-height: 26px;
  }
  & p {
    @include mq() {
      margin: 0 25px;
      text-align: left;
    }
  }
} /* イントロダクション */

.seminarPage {
  & .mainCont {
    max-width: 100%;
  }
} /* セミナーページ */
.seminarBox {
  margin: 0 auto 80px;
  @include mq() {
    padding: 25px;
    &:nth-of-type(3) {
      width: 100%;
      padding: 0;
    }
  }
  &:nth-of-type(1) {
    & .seminarBox__title {
      background-image: url(../res/img/sub/seminar_title-01.png);
      background-size: 237px 39px;
    }
  }
  &:nth-of-type(2) {
    background-color: #C3C8D4;
    padding: 30px 0;
    & .seminarBox__title {
      background-image: url(../res/img/sub/seminar_title-02.png);
      background-size: 160px 39px;
    }
  }
  &:nth-of-type(3) {
    margin-bottom: 100px;
    & .seminarBox__title {
      background-image: url(../res/img/sub/seminar_title-03.png);
      background-size: 159px 39px;
    }
  }
  &Inner {
    max-width: 800px;
    margin: 0 auto;
  }
  &__title {
    background: center center no-repeat;
    height: 39px;
    margin-bottom: 30px;
    font-size: 24px;
    color: $c-blue;
    text-align: center;
    & > span {
      display: none;
    }
  }
  & table {
    background-color: $c-white;
    width: 100%;
    & tr {
//      background-color: green;
      & th,
      & td {
        padding: 1em;
      }
      & th {
//        background-color: rebeccapurple;
        width: 180px;
        max-width: 180px;
        min-width: 180px;
        border: 1px solid #e1e1e1;
        font-weight: 100;
        @include mq() {
          width: 90px;
          max-width: 90px;
          min-width: 90px;
        }
      }
      & td {
//        background-color: turquoise;
        border: 1px solid #e1e1e1;
      }
    }
  }
}
.instructorBox {
  display: flex;
  width: 100%;
  padding: 20px;
  background-color: $c-white;
  @include mq() {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: calc(100% - 50px);
    margin: 0 auto;
    & > img {
      margin-right: 0;
    }
    & > div {
      & h3 {
        text-align: center;
      }
    }
  }
  & > img {
    display: block;
    object-fit: cover;
    width: 160px;
    height: 160px;
    margin-right: 20px;
  }
  & > div {
    width: 100%;
    & h3 {
      font-size: 18px;
      margin-bottom: 20px;
      & span {
        font-weight: 600;
      }
    }
    & p {
      line-height: 1.4;
    }
  }
}

.form {
  max-width: 800px;
  margin: 0 auto 100px;
  @include mq() {
    max-width: 100%;
  }
  &._contact fieldset div legend p {
    &:nth-of-type(1) {
      display: block;
      @include mq() {
        display: none;
      }
    }
    &:nth-of-type(2) {
      display: none;
      @include mq() {
        display: block;
      }
    }
  }
  &._recruitment fieldset div legend p {
          &:nth-of-type(1) {
                        display: block;
                        text-align: center;
                        margin: 0 auto;
                        width: 100%;
            display: block;
            @include mq() {
//              display: none;
            }
          }
          &:nth-of-type(2) {
                        display: block;
                        text-align: center;
                        margin: 0 auto;
//            display: none;
            @include mq() {
              display: block;
            }
          }
  }
  &._seminar  {
    & fieldset:first-of-type {
      background-color: $c-white;
      & legend {
        background: $c-white url(../res/img/sub/seminar_title-04.png) center center/275px 39px no-repeat;
        height: 39px;
        padding-top: 90px;
        & > span {
          display: none;
        }
      }
      & dl {
        justify-content: flex-start;
        border: 0;
        & dt {
          min-width: 250px;
          background-color: transparent;
          display: flex;
          justify-content: space-between;
            padding: .3em .5em;
          border: 0;
          @include mq() {
            justify-content: flex-start;
          }
          & span {
            font-size: 18px;
            &:first-of-type {
              order: 1;
              @include mq() {
                order: 2;
                margin-left: 10px;
              }
            }
            &:last-of-type {
              order: 2;
              background-color: #9c0048;
              background-color: pink;
              background: $c-white center center no-repeat;
              background-size: 80px 30px;
              width: 80px;
              height: 30px;
              padding: 0;
              color: $c-white;
              background-image: url(../res/img/common/btn-required-pc.png);
              &.any {
                background-image: url(../res/img/common/btn-any-pc.png);
                @include mq() {
                  order: 1;
                  background-image: url(../res/img/common/btn-any-sp.png);
                  background-size: 80px 32px;
                }
              }
              @include mq() {
                order: 1;
                background-image: url(../res/img/common/btn-required-sp.png);
                background-size: 80px 32px;
              }
            }
          }
          &:last-of-type span:last-of-type {
            display: block;
          }
        }
        & dd {
          width: calc(100% - 250px - 20px);
          padding: .3em .5em;
          background-color: transparent;
          border: 0;
          margin-bottom: 20px;
            @include mq() {
              width: 100%;
//              width: 200px;
//              min-width: 540px;
            }
          & input,
          & textarea {
            width: 100%;
            min-width: 100%;
          }
        }
      }
    }

  }
  & fieldset {
    display: block;
    background-color: $c-gray;
    margin-bottom: 60px;
    min-inline-size: 0;
    & legend {
      width: 100%;
    }
    & div {
      & legend {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        background-color: $c-black;
        width: 100%;
        margin-bottom: 30px;
        font-size: 24px;
        color: $c-white;
        text-align: center;
        @include mq() {
          flex-direction: column;
          padding: .2em 1em;
        }
        & > p {
          &:last-of-type {
            display: block;
            height: 25px;
            & > span {
              display: block;
              @include positionTR(calc(50% - 12.5px), 0, 100);
              width: 140px;
              height: 25px;
              font-size: 14px;
              color: $c-white;
              @include mq() {
                position: static;
                width: unset;
              }
            }
            
          }
        }
      }
    }
    & dl {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      font-size: 16px;
      border: 1px solid #bfbfbf;
      @include mq() {
        flex-direction: column;
        margin: 0 25px;
      }
      & dt {
        background-color: #d2d2d2;
        width: 200px;
        max-width: 200px;
        padding-left: 20px;
        padding-top: 15px;
        border-bottom: 1px solid #bfbfbf;
        font-weight: 600;
        &:last-of-type {
          border-bottom: 0;
        }
        @include mq() {
          width: 100%;
          max-width: 100%;
          text-align: center;
          padding-left: 0;
          border-bottom: 0;
        }
      }
      & dd {
        display: block;
        max-width: calc(100% - 200px);
        width: calc(100% - 200px);
        padding: 10px 20px;
        border-bottom: 1px solid #bfbfbf;
        &:last-of-type {
          border-bottom: 0;
        }
        @include mq() {
          width: 100%;
          max-width: 100%;
          background-color: #d2d2d2;
        }
        & .wpcf7-form-control-wrap {
          display: block;
          & + * {
            margin-top: 10px;
          }
        }
        & select {
          display: block;
          background-color: $c-white;
          border: 1px solid #bfbfbf;
          margin-bottom: 10px;
          &:focus {
            outline-color: $c-black;
          }
        }
        & input[type="text"],
        & input[type="tel"],
        & input[type="email"],
        & textarea {
          display: block;
          width: 540px;
          max-width: 540px;
          padding: .2em .5em;
          border: 1px solid #bfbfbf;
          @include mq() {
            background-color: #f0f0f0;
            width: 100%;
            max-width: 100%;
          }
          &:focus {
            outline-color: $c-black;
          }
          & + * {
            margin-top: 10px;
          }
          @include mq() {
            background-color: #fff;
          }
        }
        /*
        & .radioBox {
          display: inline-flex;
          justify-content: space-between;
          align-items: center;
          & input[type="radio"] {
            display: none;
            &:checked + label {
              background-color: $c-black;
              color: $c-white;
            }
          }
          @include mq() {
            display: flex;
            justify-content: center;
            margin: 0 auto;
          }
          & label {
            background-color: $c-white;
            width: 100px;
            height: 25px;
            border: 1px solid $c-black;
            margin-right: 10px;
            text-align: center;
            transition: .3s;
            &:last-of-type {
              margin-right: 0;
            }
            &:hover {
              transition: .3s;
              background-color: lighten($c-black, 30%);
              color: $c-white;
            }
          }
        }
        */
        

        & .radioBox {
          display: inline-flex;
          justify-content: space-between;
          align-items: center;
//          margin-top: 10px;
          & > span {
            display: block;
            margin-right: 10px !important;
            &:last-of-type {
              margin-right: 0 !important;
            }
            & label {
              display: block;
              background-color: $c-white;
              max-width: 150px;
              text-align: center;
              transition: .3s;
              & > input[type="radio"] {
                display: none;
                &:checked + span {
                  background-color: $c-black;
                  color: $c-white;
                }
              }
              & > span {
                display: inline-block;
                width: 100%;
                background-color: $c-white;
                padding: .2em 1em;
//                padding: 10px;
                border: 1px solid $c-black;
                &:hover {
                  transition: .3s;
                  background-color: lighten($c-black, 30%);
                  color: $c-white;
                }
              }
            }

          }
          @include mq() {
            display: flex;
            justify-content: center;
            margin: 0 auto;
          }
        }        
        & .inputTextM2 {
          display: flex;
          justify-content: flex-start;
          align-items: center;
//          width: 150px;
          width: 100%;
//          max-width: 150px;
          @include mq() {
            width: 100%;
            max-width: 100%;
          }
          & span {
            display: block;
            width: 100%;
          }
          & input[type="text"] {
            display: inline-block;
//            width: calc(100% - 20px);
            width: 100%;
//            border-right: 0;
            margin-bottom: 0;
          }
          & .wpcf7-form-control-wrap.recruitment_area {
            position: relative;
            &::before,
            &::after {
              position: absolute;
              display: block;
            }
            &::before {
              content: "m";
              top: calc(50% - 11px);
              top: 5px;
              right: 26px;
            }
            &::after {
              content: "2";
              top: 3px;
              right: 21px;
              font-size: 8px;
            }
          }

//          & label {
//            display: inline-block;
////            background-color: $c-white;
//            width: 30px;
//            padding: .2em 2px;
//            border: 1px solid #bfbfbf;
//            border-left: 0;
//            margin-top: 0;
//            & sup {
//              vertical-align: super;
//              font-size: 50%;
//            }
//            @include mq() {
//              background-color: #fff;
//              color: #ccc;
//            }
//          }
        }
        & textarea {
          height: 82px;
          min-height: 82px;
          min-width: 540px;
          max-height: 50vh;
          transition: .1s;
          @include mq() {
            min-width: 100%;
          }
        }
      }
    }
    & .formPrivacy {
      @include mq() {
        max-width: 560px;
        padding: 0 25px;
        margin: 0 auto;
//        margin: 0 25px;
      }
      &__title {
        font-size: 18px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 30px;
      }
      &__list {
        margin-bottom: 60px;
        max-width: 600px;
        margin: 0 auto;
        list-style: none;
        counter-reset: num;
        & li {
          display: flex;
          justify-content: center;
          margin-bottom: 30px;
          &::before {
            counter-increment: num;
            content: counter(num)  ".";
            display: inline-block;
            font-weight: 600;
            padding-right: 3px;
            margin-right: 10px;
          }
          & a {
            color: #118ded;
            text-decoration: underline;
          }
        }
      }
      &__confirmation1 {
        font-weight: 600;
        text-align: center;
        margin-bottom: 30px;
        @include mq() {
          text-align: left;
        }
      }
      /*
      &__checkbox {
        display: flex;
        align-items: center;
        margin: 0 auto  30px;
        & input[type="checkbox"] {
          display: none;
          &:checked + label span:nth-of-type(1) {
            background-color: $c-black;
            &::after {
              content: "\f00c";
            }
          }
        }
        & label {
          display: inline-flex;
          padding: 5px;
          margin: 0 auto;
          & span {
            display: block;
            transition: .3s;
            &:nth-of-type(1) {
              position: relative;
              width: 25px;
              height: 25px;
              border: 1px solid $c-black;;
              margin-right: 10px;
              &::after {
                display: inline-block;
                font-family: "FontAwesome";
                font-size: 18px;
                color: #fff;
                position: absolute;
                top: calc(50% - 8px);
                left: calc(50% - 8px);
              }
            }
            &:nth-of-type(2) {
              border-bottom: 1px solid transparent;
              font-size: 18px;
              font-weight: 600;
            }
          }
        }
      }
      */

      &__checkbox {
        display: flex;
        justify-content: center;
//        align-items: center;
        margin: 0 auto  30px;
width: 100%;
        .wpcf7-list-item.first.last {
          display: inline-flex;
          justify-content: center;
          align-items: center;
        }
          & input[type="checkbox"] {
            display: none;
//            &:checked + label span:nth-of-type(1) {
            &:checked + span::before {
              background-color: $c-black;
            }
            &:checked + span::after {
              content: "\f00c";
            }
          }


//        & span {
//          display: block;
//          width: 100%;
          & label {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            display: block;
            position: relative;
            width: 100%;
//            padding: 5px;
            margin: 0 auto;
            & span {
              display: block;
              display: inline-flex;
              justify-content: center;
              align-items: center;
//              width: 100%;
              transition: .3s;
//              &:nth-of-type(1) {
              &::before {
                content: "";
                display: inline-block;
                position: relative;
                width: 25px;
                height: 25px;
                border: 1px solid $c-black;;
                margin-right: 10px;
              }
              &::after {
                display: inline-block;
                font-family: "FontAwesome";
                font-size: 18px;
                color: #fff;
                position: absolute;
                top: calc(50% - 10px);
//                left: calc(50% - 8px);
                left: 5px;
              }
//              &:nth-of-type(2) {
//                border-bottom: 1px solid transparent;
//                font-size: 18px;
//                font-weight: 600;
//              }
            }
          }
//        }




        & p {
//          display: inline-block;
          & span {
            margin-right: 3px;
            & input[type="checkbox"] {
              background-color: red;
            }
          }
        }
        & label {
          display: inline-block;
          & span {
            font-size: 18px;
            font-weight: 600;
          }
        }
      }
      
      
      
      &__confirmation2 {
        margin: 0 auto 30px;
        text-align: center;
      }
    }
  }
} /* フォームパーツ */

.comingSoon {
  width: 100%;
  background-color: $c-blue;
  padding: 50px 0;
  margin: 0 auto 50px;
  & h2 {
    margin-bottom: 20px;
    font-size: 40px;
    font-weight: 100;
    text-align: center;
    color: $c-white;
    text-transform: uppercase;
  }
  & p {
    margin-bottom: 10px;
    font-size: 16px;
    text-align: center;
    color: $c-white;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
} /* セミナーページが非公開の時の表示物 */


// ******************************
// [007] メインコンテンツ（マンション経営のメリットページ）
// ******************************
.meritIntroduction {
  margin-bottom: 55px;
  & h3 {
    font-size: 18px;
    text-align: center;
    margin-bottom: 30px;
  }
  & p {
    max-width: 600px;
    margin: 0 auto;
    line-height: 26px;
    margin-bottom: 35px;
  }
  & div {
    max-width: 800px;
    margin: 0 auto;
    & h4 {
      font-size: 18px;
      color: $c-blue;
      margin-bottom: 20px;
      & + picture {
        display: block;
        margin-bottom: 40px;
        & img {
          display: block;
          width: 100%;
        }
      }
      & p {
        line-height: 26px;
      }
    }
  }
}
.meritPageNavi {
  margin: 0 auto 80px;
  & > picture  {
    display: block;
    margin: 0 auto 10px;
    max-width: 176px;
    & img {
      display: block;
      width: 100%;
    }
  }
  & p {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 40px;
    text-align: center;
  }
  & nav {
    max-width: 640px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 0 auto 80px;
    & a {
      margin: 5px;
      @include mq() {
        max-width: 200px;
        margin-bottom: 20px;
      }
      & picture {
        & img {
          width: 100%;
        }
      }
    }
  }
}
.meritSection {
  max-width: 600px;
  margin: 0 auto 80px;
  &:nth-of-type(1) {
    margin-bottom: 35px;
  }
  &:nth-of-type(2) {
    & hr + p {
      margin-bottom: 65px;
    }
    & h3 {
      margin-bottom: 20px;
    }
    & p {
      margin-bottom: 30px;
    }
    & img {
      margin-bottom: 25px;
    }
  }
  &:nth-of-type(3) {
    & h3 {
      margin-bottom: 20px;
    }
    & img {
      margin-bottom: 20px;
    }
  }
  &:nth-of-type(4) {
    & h3 {
      margin-bottom: 20px;
      & + p {
        margin-bottom: 30px;
      }
    }
    & img {
      margin-bottom: 40px;
      & + p {
        margin-top: -20px;
      }
    }
  }
  &:nth-of-type(5) {
    & h3 {
      margin-bottom: 20px;
    }
    & img {
      margin-bottom: 20px;
    }
  }
  &:nth-of-type(6) {
    max-width: 815px;
    margin-bottom: 100px;
    & p {
      margin-bottom: 40px;
      text-align: center;
    }
  }
  & h2 {
    margin-bottom: 25px;
    font-size: 40px;
    color: $c-blue;
    text-align: center;
  }
  & hr {
    width: 65px;
    border-width: 2px;
    border: 1px solid $c-black;
    margin: 0 auto 30px;
  }
  & > p {
    margin-bottom: 40px;
    line-height: 26px;
  }
  &__caption {
    margin-bottom: 40px;
    line-height: 26px;
    @include mq() {
      text-align: center;
    }
    & br {
      display: none;
      @include mq() {
        display: block;
      }
    }
  }
  & h3 {
    margin: 0 auto 30px;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    & .br {
      display: none;
      @include mq() {
        display: block;
      }
    }
  }
  & ol {
    margin-bottom: 35px;
    line-height: 26px;
    list-style: none;
    & li {
      padding-left: 0.5em;
      display: flex;
      
      & span {
        display: block;
        padding-right: 3px;
      }
      & p {
        display: inline;
      }
    }
  }
  & ul {
    width: 250px;
    margin: 0 auto 30px;
    & li {
      margin-bottom: 10px;
    }
  }
  & picture {
    display: block;
    margin: 0 auto 60px;
    & img {
      display: block;
      width: 100%;
      margin: 0 auto 60px;
    }
  }
  & .ownerComment {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 auto;
    & div {
      max-width: 400px;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      margin: 0 auto 40px;
      &:last-of-type {
        margin-bottom: 0;
      }
      & picture {
        margin: 0 auto;
        & img {
          width: 100%;
          margin: 0;
        }
      }
      & span {
        display: block;
        margin-bottom: 5px;
      }
      
    }
  }
}













//.siteTitle {
//  width: 100%;
//  background-color: #ff0;
//  height: 400px;
//}
